import { FC } from 'react';
import { CommonIconProps, defaultPathClassName } from './utils';

const DownloadIcon: FC<CommonIconProps> = props => {
  const { size = '100%', className, pathClassName = defaultPathClassName } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.6537 15.9575C11.6187 15.9338 11.5862 15.9075 11.5575 15.8787L7.43373 11.755C7.18998 11.5112 7.18998 11.115 7.43373 10.8713C7.67748 10.6275 8.07373 10.6275 8.31748 10.8713L11.375 13.9288V4.4375C11.375 4.0925 11.655 3.8125 12 3.8125C12.345 3.8125 12.625 4.0925 12.625 4.4375V13.9288L15.6825 10.8713C15.9262 10.6275 16.3225 10.6275 16.5662 10.8713C16.81 11.115 16.81 11.5112 16.5662 11.755L12.4425 15.8787C12.4125 15.9087 12.3812 15.935 12.3462 15.9575C12.3375 15.9633 12.3283 15.9679 12.3192 15.9724C12.3127 15.9756 12.3062 15.9789 12.3 15.9825C12.2956 15.985 12.2912 15.9875 12.2869 15.99C12.2712 15.999 12.2553 16.0082 12.2387 16.015C12.2248 16.0202 12.2103 16.0242 12.1957 16.0283C12.1892 16.0301 12.1827 16.0318 12.1762 16.0338C12.1697 16.0356 12.1631 16.0378 12.1565 16.0401C12.1454 16.0438 12.1342 16.0477 12.1225 16.05C12.0825 16.0588 12.0412 16.0625 12 16.0625C11.9587 16.0625 11.9175 16.0575 11.8775 16.05C11.8666 16.0478 11.8566 16.0444 11.8465 16.0409C11.8391 16.0384 11.8316 16.0359 11.8237 16.0338C11.8025 16.0288 11.7812 16.0238 11.7612 16.015C11.7446 16.0082 11.7288 15.999 11.7131 15.99C11.7087 15.9875 11.7044 15.985 11.7 15.9825C11.6937 15.9789 11.6873 15.9756 11.6808 15.9724C11.6717 15.9679 11.6625 15.9633 11.6537 15.9575ZM21.6875 15.4375C21.6875 15.0925 21.9675 14.8125 22.3125 14.8125C22.6575 14.8125 22.9375 15.0925 22.9375 15.4375V16.8125C22.9375 18.6737 21.4237 20.1875 19.5625 20.1875H4.4375C2.57625 20.1875 1.0625 18.6737 1.0625 16.8125V15.4375C1.0625 15.0925 1.3425 14.8125 1.6875 14.8125C2.0325 14.8125 2.3125 15.0925 2.3125 15.4375V16.8125C2.3125 17.9837 3.26625 18.9375 4.4375 18.9375H19.5625C20.7337 18.9375 21.6875 17.9837 21.6875 16.8125V15.4375Z"
        className={pathClassName}
      />
    </svg>
  );
};

export default DownloadIcon;
