import { ImportProcessStatus } from '../../components/organisms/_modals/ImportDataModal/utils';
import {
  PlanTypes as GeneratedPlanTypes,
  AvairablePlanTypes as GeneratedAvairablePlanTypes,
  O3CycleKeys as GeneratedO3CycleKeys,
  CheckInCycleKeys as GeneratedCheckInCycleKeys,
  PeriodType as GeneratedPeriodType,
  AvairablePeriodType as GeneratedAvairablePeriodType,
  CheckInConditions as CheckInConditionsType,
  PublicationRanges as PublicationRangesType,
  ObjectiveResultTypes as ObjectiveResultType,
  ResultIndicatorTypes as ResultIndicatorType,
  NotificationOptions as GeneratedNotificationOptions,
  CycleWeekThresholds,
  O3CompanyTopicStatuses as GeneratedO3CompanyTopicStatuses,
  PostSurveyTopicOtherThanO3TopicItems as GeneratedPostSurveyTopicOtherThanO3TopicItems,
  O3MeetingStatuses as GeneratedO3MeetingStatuses,
  O3MeetingStepStatuses as GeneratedO3MeetingStepStatuses,
  O3sAnalyticsFilters as GeneratedO3sAnalyticsFilters,
  FeedbackSheetSenderTypes as GeneratedFeedbackSheetSenderTypes,
  FeedbackSheetItemTypes as GeneratedFeedbackSheetItemTypes,
  FeedbackSheetItemAuthorTypesOptions as GeneratedFeedbackSheetItemAuthorTypesOptions,
  ObjectiveSheetItemAuthorTypes,
  UserRoles as GeneratedUserRoles,
  FeedbackSheetFormStatuses as GeneratedFeedbackSheetFormStatuses,
  PaymentTypes as GeneratedPaymentTypes,
  CompanyStatuses as GeneratedCompanyStatuses,
  CompanyChatworkNotificationFlag as GeneratedCompanyChatworkNotificationFlag,
  CompanyIntegrationFlag,
  CompanySlackNotificationFlag as GeneratedCompanySlackNotificationFlag,
  ObjectiveSourceTypes,
  UserStatuses as GeneratedUserStatuses,
  O3PreSurveyItemTypes as GeneratedO3PreSurveyItemTypes,
  Priorities as GeneratedPriorities,
  BadgeTypes as GeneratedBadgeTypes,
  GuidelineTypes as GeneratedGuidelineTypes,
  FeedbackSheetItemElementTypes as GeneratedFeedbackSheetItemElementTypes,
  FeedbackSheetObjectiveItemElementTypes as GeneratedFeedbackSheetObjectiveItemElementTypes,
  BetaEntryStatuses,
  ManagementIndicatorTypes as GeneratedManagementIndicatorTypes,
  CompanyIpRestrictionPolicies,
  CustomPublicationRanges as CustomPublicationRangesType,
  FeedbackReceiverFilterComparisonOperatorTypes as GeneratedFeedbackReceiverFilterComparisonOperatorTypes,
  ObjectiveSortKeys as GeneratedObjectiveSortKeys,
  ObjectiveCategoryPeriodTypeOptions as GeneratedObjectiveCategoryPeriodTypeOptions,
  FeedbackMiddleLayerTypes,
  FeedbackPublicationTypes,
  ObjectiveSheetPublicationRanges as GeneratedObjectiveSheetPublicationRanges,
  ObjectiveSheetItemTypes,
  ObjectiveSheetItemElementTypes,
  OkrConfidenceLevels,
  FeedbackSheetTargetObjectiveTypes,
  ObjectiveSheetFormApprovalStatuses,
  ObjectiveSheetFormApprovalEventTypes,
  // ObjectiveSheetFormApprovalOperations,
  ObjectiveSheetFormFilterStatuses,
  ObjectiveSheetFormFilterApprovalNotRequiredStatuses,
  ObjectiveSheetFormFilterApprovalRequiredStatuses,
  UserGoogleCalendarStatus,
  Team,
  OkrDifficultyLevels,
  ManagementSortKeys,
  ExportedFileTypes,
  ObjectiveSheetFormStatuses,
  ExtO3MeetingStatuses,
  ExtO3ProjectTypes,
  ExtO3PairStatuses,
  ExtO3TicketStatuses,
  OrderByEnum,
  ExtO3ProjectMentorStatuses,
  ExtO3ProjectMenteeStatuses,
} from '../../generated/graphql';

import i18next from '../../lib/i18n';
import { UseDictionnaryReturn } from '../../lib/use-me';
import EnumMap from './enum-map';

export interface Option {
  order: number;
  value: string;
  label: string;
  labelFunction(): string;
}

export const PlanTypes = new EnumMap({
  [GeneratedPlanTypes.Standard]: {
    order: 10,
    value: GeneratedPlanTypes.Standard,
    labelFunction: () => i18next.t('スタンダードプラン'),
  },
  [GeneratedPlanTypes.Full]: {
    order: 10,
    value: GeneratedPlanTypes.Full,
    labelFunction: () => i18next.t('フルパッケージプラン'),
  },
  [GeneratedPlanTypes.Ppo3]: {
    order: 10,
    value: GeneratedPlanTypes.Ppo3,
    labelFunction: () => i18next.t('1on1プラン'),
  },
});

export const AvairablePlanTypes = new EnumMap({
  [GeneratedAvairablePlanTypes.Full]: {
    order: 10,
    value: GeneratedAvairablePlanTypes.Full,
    labelFunction: () => i18next.t('フルパッケージプラン'),
  },
  [GeneratedAvairablePlanTypes.Ppo3]: {
    order: 10,
    value: GeneratedAvairablePlanTypes.Ppo3,
    labelFunction: () => i18next.t('1on1プラン'),
  },
});

export const CheckInConditions = new EnumMap({
  [CheckInConditionsType.Fine]: {
    order: 10,
    value: CheckInConditionsType.Fine,
    labelFunction: () => i18next.t('とても順調'),
  },
  [CheckInConditionsType.Cloudy]: {
    order: 20,
    value: CheckInConditionsType.Cloudy,
    labelFunction: () => i18next.t('予定通り'),
  },
  [CheckInConditionsType.Overcast]: {
    order: 30,
    value: CheckInConditionsType.Overcast,
    labelFunction: () => i18next.t('やや遅れ'),
  },
  [CheckInConditionsType.Raining]: {
    order: 40,
    value: CheckInConditionsType.Raining,
    labelFunction: () => i18next.t('かなり遅れ'),
  },
});

export const CustomPublicationRanges = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    [CustomPublicationRangesType.Team]: {
      order: 10,
      value: CustomPublicationRangesType.Team,
      labelFunction: () => i18next.t('自分が所属する{{wTeam}}', dict),
    },
    [CustomPublicationRangesType.Manager]: {
      order: 10,
      value: CustomPublicationRangesType.Manager,
      labelFunction: () => i18next.t('マネージャー'),
    },
    [CustomPublicationRangesType.ReportLine]: {
      order: 10,
      value: CustomPublicationRangesType.ReportLine,
      labelFunction: () => i18next.t('直属以上のマネージャー'),
    },
  });

export const PublicationRanges = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    [PublicationRangesType.DirectManager]: {
      order: 10,
      value: PublicationRangesType.DirectManager,
      labelFunction: () => i18next.t('直属以上のマネージャー'),
    },
    [PublicationRangesType.Manager]: {
      order: 20,
      value: PublicationRangesType.Manager,
      labelFunction: () => i18next.t('マネージャー'),
    },
    [PublicationRangesType.Team]: {
      order: 30,
      value: PublicationRangesType.Team,
      labelFunction: () => i18next.t('{{wTeam}}（自分が所属する{{wTeam}}には公開）', dict),
    },
    [PublicationRangesType.Public]: {
      order: 40,
      value: PublicationRangesType.Public,
      labelFunction: () => i18next.t('パブリック（企業内の全員に公開）'),
    },
    [PublicationRangesType.Customize]: {
      order: 50,
      value: PublicationRangesType.Customize,
      labelFunction: () => i18next.t('限定公開（公開する{{wTeam}}・人を選択）', dict),
    },
  });

export const AvailablePublicationRanges = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    [PublicationRangesType.Public]: {
      order: 10,
      value: PublicationRangesType.Public,
      labelFunction: () => i18next.t('パブリック（企業内の全員に公開）'),
    },
    [PublicationRangesType.Customize]: {
      order: 20,
      value: PublicationRangesType.Customize,
      labelFunction: () => i18next.t('限定公開（公開する{{wTeam}}・人を選択）', dict),
    },
  });

export const UserAvailablePublicationRanges = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    [PublicationRangesType.Public]: {
      order: 10,
      value: PublicationRangesType.Public,
      labelFunction: () => i18next.t('パブリック（企業内の全員に公開）'),
    },
    [PublicationRangesType.Customize]: {
      order: 20,
      value: PublicationRangesType.Customize,
      labelFunction: () => i18next.t('限定公開（公開する{{wTeam}}・人を選択）', dict),
    },
  });
export const TeamAvailablePublicationRanges = new EnumMap({
  [PublicationRangesType.Public]: {
    order: 10,
    value: PublicationRangesType.Public,
    labelFunction: () => i18next.t('パブリック（企業内の全員に公開）'),
  },
});

export const TeamAvailablePublicationRangesWithCustom = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    [PublicationRangesType.Public]: {
      order: 10,
      value: PublicationRangesType.Public,
      labelFunction: () => i18next.t('パブリック（企業内の全員に公開）'),
    },
    [PublicationRangesType.Customize]: {
      order: 20,
      value: PublicationRangesType.Customize,
      labelFunction: () => i18next.t('限定公開（公開する{{wTeam}}・人を選択）', dict),
    },
  });

export const CompanyAvailablePublicationRanges = new EnumMap({
  [PublicationRangesType.Public]: {
    order: 10,
    value: PublicationRangesType.Public,
    labelFunction: () => i18next.t('パブリック（企業内の全員に公開）'),
  },
});

export const getPublicationRanges = (
  sourceType: ObjectiveSourceTypes,
  teamSetting: Pick<Partial<Team>, 'enableCustomPublicationRanges'> | null,
  dict: UseDictionnaryReturn,
) => {
  switch (sourceType) {
    case ObjectiveSourceTypes.User:
      return UserAvailablePublicationRanges(dict);
    case ObjectiveSourceTypes.Team:
      if (teamSetting?.enableCustomPublicationRanges) {
        return TeamAvailablePublicationRangesWithCustom(dict);
      }
      return TeamAvailablePublicationRanges;
    case ObjectiveSourceTypes.Company:
      return CompanyAvailablePublicationRanges;
    default:
      return PublicationRanges(dict);
  }
};

export const CustomPropertyPublicationRanges = new EnumMap({
  [PublicationRangesType.Public]: {
    order: 10,
    value: PublicationRangesType.Public,
    labelFunction: () => i18next.t('パブリック（企業内の全員に公開）'),
  },
  [PublicationRangesType.Private]: {
    order: 20,
    value: PublicationRangesType.Private,
    labelFunction: () => i18next.t('プライベート'),
  },
});

export const ObjectiveResultTypes = new EnumMap({
  [ObjectiveResultType.RateIndicator]: {
    order: 10,
    value: ObjectiveResultType.RateIndicator,
    labelFunction: () => i18next.t('スライダー'),
  },
  [ObjectiveResultType.NumberIndicator]: {
    order: 20,
    value: ObjectiveResultType.NumberIndicator,
    labelFunction: () => i18next.t('数値入力'),
  },
  [ObjectiveResultType.KeyResults]: {
    order: 30,
    value: ObjectiveResultType.KeyResults,
    labelFunction: () => i18next.t('Key Result'),
  },
});

export const ResultIndicatorTypes = new EnumMap({
  [ResultIndicatorType.Rate]: {
    order: 10,
    value: ResultIndicatorType.Rate,
    labelFunction: () => i18next.t('スライダー'),
  },
  [ResultIndicatorType.Number]: {
    order: 20,
    value: ResultIndicatorType.Number,
    labelFunction: () => i18next.t('数値入力'),
  },
  [ResultIndicatorType.Bool]: {
    order: 30,
    value: ResultIndicatorType.Bool,
    labelFunction: () => i18next.t('チェック'),
  },
});

export const PeriodType = new EnumMap({
  [GeneratedPeriodType.Month]: {
    order: 10,
    value: GeneratedPeriodType.Month,
    labelFunction: () => i18next.t('月次'),
  },
  [GeneratedPeriodType.Quarter]: {
    order: 20,
    value: GeneratedPeriodType.Quarter,
    labelFunction: () => i18next.t('四半期'),
  },
  [GeneratedPeriodType.FourMonth]: {
    order: 30,
    value: GeneratedPeriodType.FourMonth,
    labelFunction: () => i18next.t('三半期'),
  },
  [GeneratedPeriodType.HalfYear]: {
    order: 40,
    value: GeneratedPeriodType.HalfYear,
    labelFunction: () => i18next.t('半期'),
  },
  [GeneratedPeriodType.Year]: {
    order: 50,
    value: GeneratedPeriodType.Year,
    labelFunction: () => i18next.t('年次'),
  },
});

export const AvairablePeriodType = new EnumMap({
  [GeneratedAvairablePeriodType.Month]: {
    order: 10,
    value: GeneratedAvairablePeriodType.Month,
    labelFunction: () => i18next.t('月次'),
  },
  [GeneratedAvairablePeriodType.Quarter]: {
    order: 20,
    value: GeneratedAvairablePeriodType.Quarter,
    labelFunction: () => i18next.t('四半期'),
  },
  [GeneratedAvairablePeriodType.HalfYear]: {
    order: 40,
    value: GeneratedAvairablePeriodType.HalfYear,
    labelFunction: () => i18next.t('半期'),
  },
  [GeneratedAvairablePeriodType.Year]: {
    order: 50,
    value: GeneratedAvairablePeriodType.Year,
    labelFunction: () => i18next.t('年次'),
  },
});

export const ObjectiveCategoryPeriodTypeOptions = new EnumMap({
  [GeneratedObjectiveCategoryPeriodTypeOptions.Any]: {
    order: 10,
    value: GeneratedObjectiveCategoryPeriodTypeOptions.Any,
    labelFunction: () => i18next.t('任意'),
  },
  [GeneratedObjectiveCategoryPeriodTypeOptions.Month]: {
    order: 10,
    value: GeneratedObjectiveCategoryPeriodTypeOptions.Month,
    labelFunction: () => i18next.t('月次'),
  },
  [GeneratedObjectiveCategoryPeriodTypeOptions.Quarter]: {
    order: 20,
    value: GeneratedObjectiveCategoryPeriodTypeOptions.Quarter,
    labelFunction: () => i18next.t('四半期'),
  },
  [GeneratedObjectiveCategoryPeriodTypeOptions.HalfYear]: {
    order: 40,
    value: GeneratedObjectiveCategoryPeriodTypeOptions.HalfYear,
    labelFunction: () => i18next.t('半期'),
  },
  [GeneratedObjectiveCategoryPeriodTypeOptions.Year]: {
    order: 50,
    value: GeneratedObjectiveCategoryPeriodTypeOptions.Year,
    labelFunction: () => i18next.t('年次'),
  },
});

export const CheckInCycleKeys = new EnumMap({
  [GeneratedCheckInCycleKeys.None]: {
    order: 10,
    value: 'none',
    labelFunction: () => i18next.t('通知なし'),
  },
  [GeneratedCheckInCycleKeys.W1]: {
    order: 20,
    value: GeneratedCheckInCycleKeys.W1,
    labelFunction: () => i18next.t('毎週'),
  },
  [GeneratedCheckInCycleKeys.W2]: {
    order: 30,
    value: GeneratedCheckInCycleKeys.W2,
    labelFunction: () => i18next.t('隔週'),
  },
  [GeneratedCheckInCycleKeys.W4]: {
    order: 40,
    value: GeneratedCheckInCycleKeys.W4,
    labelFunction: () =>
      i18next.t('{{count}}週間ごと', {
        count: 4,
      }),
  },
});

export const AvailableCheckInCycleKeys = new EnumMap({
  [GeneratedCheckInCycleKeys.W1]: {
    order: 10,
    value: GeneratedCheckInCycleKeys.W1,
    labelFunction: () =>
      i18next.t('{{count}}週間ごと', {
        count: 1,
      }),
  },
  [GeneratedCheckInCycleKeys.W2]: {
    order: 20,
    value: GeneratedCheckInCycleKeys.W2,
    labelFunction: () =>
      i18next.t('{{count}}週間ごと（推奨）', {
        count: 2,
      }),
  },
  [GeneratedCheckInCycleKeys.W4]: {
    order: 30,
    value: GeneratedCheckInCycleKeys.W4,
    labelFunction: () =>
      i18next.t('{{count}}週間ごと', {
        count: 4,
      }),
  },
});

export const WeekDayOptions = new EnumMap({
  [CycleWeekThresholds.Sunday]: {
    order: 0,
    value: CycleWeekThresholds.Sunday,
    labelFunction: () => i18next.t('日曜日'),
    key: 0,
  },
  [CycleWeekThresholds.Monday]: {
    order: 1,
    value: CycleWeekThresholds.Monday,
    labelFunction: () => i18next.t('月曜日'),
    key: 1,
  },
  [CycleWeekThresholds.Tuesday]: {
    order: 2,
    value: CycleWeekThresholds.Tuesday,
    labelFunction: () => i18next.t('火曜日'),
    key: 2,
  },
  [CycleWeekThresholds.Wednesday]: {
    order: 3,
    value: CycleWeekThresholds.Wednesday,
    labelFunction: () => i18next.t('水曜日'),
    key: 3,
  },
  [CycleWeekThresholds.Thursday]: {
    order: 4,
    value: CycleWeekThresholds.Thursday,
    labelFunction: () => i18next.t('木曜日'),
    key: 4,
  },
  [CycleWeekThresholds.Friday]: {
    order: 5,
    value: CycleWeekThresholds.Friday,
    labelFunction: () => i18next.t('金曜日'),
    key: 5,
  },
  [CycleWeekThresholds.Saturday]: {
    order: 6,
    value: CycleWeekThresholds.Saturday,
    labelFunction: () => i18next.t('土曜日'),
    key: 6,
  },
});

export const O3CycleKeys = new EnumMap({
  [GeneratedO3CycleKeys.W1]: {
    order: 10,
    value: 'w1',
    labelFunction: () => i18next.t('毎週'),
  },
  [GeneratedO3CycleKeys.W2]: {
    order: 20,
    value: 'w2',
    labelFunction: () => i18next.t('隔週'),
  },
  [GeneratedO3CycleKeys.W3]: {
    order: 30,
    value: 'w3',
    labelFunction: () =>
      i18next.t('{{count}}週間ごと', {
        count: 3,
      }),
  },
  [GeneratedO3CycleKeys.W4]: {
    order: 40,
    value: 'w4',
    labelFunction: () =>
      i18next.t('{{count}}週間ごと', {
        count: 4,
      }),
  },
  [GeneratedO3CycleKeys.W8]: {
    order: 41,
    value: 'w8',
    labelFunction: () =>
      i18next.t('{{count}}週間ごと', {
        count: 8,
      }),
  },
  [GeneratedO3CycleKeys.M1]: {
    order: 50,
    value: 'm1',
    labelFunction: () => i18next.t('毎月'),
  },
});

export const ActiveO3CycleKeys = new EnumMap({
  [GeneratedO3CycleKeys.W1]: {
    order: 10,
    value: 'w1',
    labelFunction: () => i18next.t('毎週'),
  },
  [GeneratedO3CycleKeys.W2]: {
    order: 20,
    value: 'w2',
    labelFunction: () => i18next.t('隔週'),
  },
  [GeneratedO3CycleKeys.W3]: {
    order: 30,
    value: 'w3',
    labelFunction: () =>
      i18next.t('{{count}}週間ごと', {
        count: 3,
      }),
  },
  [GeneratedO3CycleKeys.W4]: {
    order: 40,
    value: 'w4',
    labelFunction: () =>
      i18next.t('{{count}}週間ごと', {
        count: 4,
      }),
  },
  [GeneratedO3CycleKeys.W8]: {
    order: 41,
    value: 'w8',
    labelFunction: () =>
      i18next.t('{{count}}週間ごと', {
        count: 8,
      }),
  },
});

/**
 * 通知の設定項目
 */
export const NotificationOptions = new EnumMap({
  [GeneratedNotificationOptions.Notify]: {
    order: 10,
    value: 'notify',
    labelFunction: () => i18next.t('通知する'),
  },
  [GeneratedNotificationOptions.DoNotNotify]: {
    order: 20,
    value: 'doNotNotify',
    labelFunction: () => i18next.t('通知しない'),
  },
});

export const O3CompanyTopicStatuses = new EnumMap({
  [GeneratedO3CompanyTopicStatuses.Active]: {
    order: 10,
    value: 'active',
    labelFunction: () => i18next.t('有効'),
  },
  [GeneratedO3CompanyTopicStatuses.Inactive]: {
    order: 20,
    value: 'inactive',
    labelFunction: () => i18next.t('無効'),
  },
  [GeneratedO3CompanyTopicStatuses.Deleted]: {
    order: 30,
    value: 'deleted',
    labelFunction: () => i18next.t('論理削除済み'),
  },
});

export const PostSurveyTopicOtherThanO3TopicItems = new EnumMap({
  [GeneratedPostSurveyTopicOtherThanO3TopicItems.Kpt]: {
    order: 10,
    value: 'kpt',
    labelFunction: () => i18next.t('良かったこと・悪かったことについて'),
  },
  [GeneratedPostSurveyTopicOtherThanO3TopicItems.Na]: {
    order: 10,
    value: 'na',
    labelFunction: () => i18next.t('該当なし（コメント欄に話した内容を記入してください）'),
  },
});

export const O3MeetingStatuses = new EnumMap({
  [GeneratedO3MeetingStatuses.Active]: {
    order: 10,
    value: 'active',
    labelFunction: () => i18next.t('有効'),
  },
  [GeneratedO3MeetingStatuses.Deleted]: {
    order: 20,
    value: 'deleted',
    labelFunction: () => i18next.t('削除済'),
  },
  [GeneratedO3MeetingStatuses.Skipped]: {
    order: 30,
    value: 'skipped',
    labelFunction: () => i18next.t('スキップ'),
  },
});

export const O3MeetingStepStatuses = new EnumMap({
  [GeneratedO3MeetingStepStatuses.Skipped]: {
    order: 0,
    value: 'skipped',
    labelFunction: () => i18next.t('中止されました'),
  },
  [GeneratedO3MeetingStepStatuses.PreSurvey]: {
    order: 10,
    value: 'preSurvey',
    labelFunction: () => i18next.t('事前アンケート未提出'),
  },
  [GeneratedO3MeetingStepStatuses.Progress]: {
    order: 20,
    value: 'progress',
    labelFunction: () => i18next.t('1on1実施中'),
  },
  [GeneratedO3MeetingStepStatuses.Done]: {
    order: 30,
    value: 'done',
    labelFunction: () => i18next.t('完了'),
  },
  [GeneratedO3MeetingStepStatuses.PreSurveyWarning]: {
    order: 40,
    value: 'preSurveyWarning',
    labelFunction: () => i18next.t('事前アンケート未提出'),
  },
  [GeneratedO3MeetingStepStatuses.PostSurvey]: {
    order: 50,
    value: 'postSurvey',
    labelFunction: () => i18next.t('1on1レポート未提出'),
  },
  [GeneratedO3MeetingStepStatuses.PostSurveyWarning]: {
    order: 60,
    value: 'postSurveyWarning',
    labelFunction: () => i18next.t('1on1レポート未提出'),
  },
});

/*
 * 1on1分析のフィルター
 */

// export const genO3sAnalyticsFilters = (dict: UseDictionnaryReturn) => {
//   return new EnumMap({
//     [GeneratedO3sAnalyticsFilters.Mentor]: {
//       order: 10,
//       value: 'mentor',
//       labelFunction: () => i18next.t('{{wMentor}}ごとに表示', dict),
//     },
//     [GeneratedO3sAnalyticsFilters.Mentee]: {
//       order: 20,
//       value: 'mentee',
//       labelFunction: () => i18next.t('{{wMentee}}ごとに表示', dict),
//     },
//     [GeneratedO3sAnalyticsFilters.Team]: {
//       order: 30,
//       value: 'team',
//       labelFunction: () => i18next.t('{{wTeam}}ごとに表示', dict),
//     },
//   });
// };

export const genO3sSatisfactionFilters = (dict: UseDictionnaryReturn) => {
  return new EnumMap({
    [GeneratedO3sAnalyticsFilters.Mentor]: {
      order: 10,
      value: 'mentor',
      labelFunction: () => i18next.t('{{wMentor}}ごとの充実度', dict),
    },
    [GeneratedO3sAnalyticsFilters.Mentee]: {
      order: 20,
      value: 'mentee',
      labelFunction: () => i18next.t('{{wMentee}}ごとの充実度', dict),
    },
    [GeneratedO3sAnalyticsFilters.Team]: {
      order: 30,
      value: 'team',
      labelFunction: () => i18next.t('{{wTeam}}ごとの充実度', dict),
    },
  });
};

export const genO3sImplementationAnalyticsFilters = (dict: UseDictionnaryReturn) => {
  return new EnumMap({
    mentor: {
      order: 10,
      value: 'mentor',
      labelFunction: () => i18next.t('{{wMentor}}ごとの実施状況', dict),
    },
    mentee: {
      order: 20,
      value: 'mentee',
      labelFunction: () => i18next.t('{{wMentee}}ごとの実施状況', dict),
    },
    team: {
      order: 30,
      value: 'team',
      labelFunction: () => i18next.t('{{wTeam}}ごとの実施状況', dict),
    },
  });
};

/**
 * フィードバック分析のフィルター
 */
export const FeedbackAnalyticsFilters = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    sender: {
      order: 10,
      value: 'sender',
      labelFunction: () => i18next.t('フィードバックを送る人別に見る'),
    },
    team: {
      order: 20,
      value: 'team',
      labelFunction: () => i18next.t('{{wTeam}}別に見る', dict),
    },
  });

export const FeedbackSheetSenderTypes = new EnumMap({
  [GeneratedFeedbackSheetSenderTypes.Every]: {
    order: 10,
    value: GeneratedFeedbackSheetSenderTypes.Every,
    labelFunction: () => i18next.t('フィードバックを複数人で作成する（共同編集）'),
  },
  [GeneratedFeedbackSheetSenderTypes.Each]: {
    order: 20,
    value: GeneratedFeedbackSheetSenderTypes.Each,
    labelFunction: () => i18next.t('フィードバックを担当者が1人ずつ作成'),
  },
});

export const FeedbackSheetItemTypes = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    [GeneratedFeedbackSheetItemTypes.Guideline]: {
      order: 10,
      value: GeneratedFeedbackSheetItemTypes.Guideline,
      labelFunction: () => i18next.t('説明'),
    },
    [GeneratedFeedbackSheetItemTypes.Comment]: {
      order: 20,
      value: GeneratedFeedbackSheetItemTypes.Comment,
      labelFunction: () => i18next.t('フリーコメント'),
    },
    [GeneratedFeedbackSheetItemTypes.SingleLine]: {
      order: 30,
      value: GeneratedFeedbackSheetItemTypes.SingleLine,
      labelFunction: () => i18next.t('１行コメント'),
    },
    [`${GeneratedFeedbackSheetItemTypes.Listing}-${GeneratedFeedbackSheetItemElementTypes.SingleLine}`]:
      {
        order: 40,
        value: `${GeneratedFeedbackSheetItemTypes.Listing}-${GeneratedFeedbackSheetItemElementTypes.SingleLine}`,
        labelFunction: () => i18next.t('箇条書き'),
      },
    [GeneratedFeedbackSheetItemTypes.Grade]: {
      order: 50,
      value: GeneratedFeedbackSheetItemTypes.Grade,
      labelFunction: () => i18next.t('選択式（レーティング）'),
    },
    [GeneratedFeedbackSheetItemTypes.Number]: {
      order: 60,
      value: GeneratedFeedbackSheetItemTypes.Number,
      labelFunction: () => i18next.t('数値'),
    },
    [GeneratedFeedbackSheetItemTypes.Objective]: {
      order: 70,
      value: GeneratedFeedbackSheetItemTypes.Objective,
      labelFunction: () => i18next.t('目標に対する評価'),
    },
    [GeneratedFeedbackSheetItemTypes.Property]: {
      order: 80,
      value: GeneratedFeedbackSheetItemTypes.Property,
      labelFunction: () => i18next.t('{{wSelfLayerAssignee}}のカスタム属性', dict),
    },
    [`${GeneratedFeedbackSheetItemTypes.Listing}-${GeneratedFeedbackSheetItemElementTypes.Section}`]:
      {
        order: 90,
        value: `${GeneratedFeedbackSheetItemTypes.Listing}-${GeneratedFeedbackSheetItemElementTypes.Section}`,
        labelFunction: () => i18next.t('セクション（項目グループ）'),
      },
  });

export const FeedbackSheetItemElementTypes = new EnumMap({
  [GeneratedFeedbackSheetItemElementTypes.Section]: {
    order: 1,
    value: GeneratedFeedbackSheetItemElementTypes.Section,
    labelFunction: () => i18next.t('セクション'),
  },
  [GeneratedFeedbackSheetItemElementTypes.SingleLine]: {
    order: 10,
    value: GeneratedFeedbackSheetItemElementTypes.SingleLine,
    labelFunction: () => i18next.t('１行コメント'),
  },
  [GeneratedFeedbackSheetItemElementTypes.Comment]: {
    order: 11,
    value: GeneratedFeedbackSheetItemElementTypes.Comment,
    labelFunction: () => i18next.t('フリーコメント'),
  },
  [GeneratedFeedbackSheetItemElementTypes.Grade]: {
    order: 20,
    value: GeneratedFeedbackSheetItemElementTypes.Grade,
    labelFunction: () => i18next.t('選択式（レーティング）'),
  },
  [GeneratedFeedbackSheetItemElementTypes.Number]: {
    order: 30,
    value: GeneratedFeedbackSheetItemElementTypes.Number,
    labelFunction: () => i18next.t('数値'),
  },
});

export const FeedbackSheetObjectiveItemElementTypes = new EnumMap({
  [GeneratedFeedbackSheetObjectiveItemElementTypes.Comment]: {
    order: 11,
    value: GeneratedFeedbackSheetObjectiveItemElementTypes.Comment,
    labelFunction: () => i18next.t('フリーコメント'),
  },
  [GeneratedFeedbackSheetObjectiveItemElementTypes.Grade]: {
    order: 20,
    value: GeneratedFeedbackSheetObjectiveItemElementTypes.Grade,
    labelFunction: () => i18next.t('選択式（レーティング）'),
  },
  [GeneratedFeedbackSheetObjectiveItemElementTypes.Number]: {
    order: 30,
    value: GeneratedFeedbackSheetObjectiveItemElementTypes.Number,
    labelFunction: () => i18next.t('数値'),
  },
});

export const EvaluationScopeSettingsItems = new EnumMap({
  enableIndividualEvaluation: {
    order: 10,
    value: 'enableIndividualEvaluation',
    labelFunction: () => i18next.t('個別評価（個々の目標に対して評価を書く）'),
  },
  enableOverallEvaluation: {
    order: 20,
    value: 'enableOverallEvaluation',
    labelFunction: () => i18next.t('総合評価（目標全体に対して評価を書く）'),
  },
});

export const OverallScoreSettingsItems = new EnumMap({
  showTotalScore: {
    order: 10,
    value: 'showTotalScore',
    labelFunction: () => i18next.t('「総合評点」を自動計出し、シートに表示する'),
  },
  enableTotalWeightValidation: {
    order: 20,
    value: 'enableTotalWeightValidation',
    labelFunction: () => i18next.t('目標のウェイトの合計が「100」になることを必須にする'),
  },
});

export const FeedbackSheetItemAuthorTypesOptions = (fbDict: UseDictionnaryReturn) =>
  new EnumMap({
    [GeneratedFeedbackSheetItemAuthorTypesOptions.Both]: {
      order: 10,
      value: GeneratedFeedbackSheetItemAuthorTypesOptions.Both,
      labelFunction: () => i18next.t('{{wSelfLayerAssignee}}と評価者の両方', fbDict),
    },
    [GeneratedFeedbackSheetItemAuthorTypesOptions.Self]: {
      order: 20,
      value: GeneratedFeedbackSheetItemAuthorTypesOptions.Self,
      labelFunction: () => i18next.t('{{wSelfLayerAssignee}}のみ', fbDict),
    },
    [GeneratedFeedbackSheetItemAuthorTypesOptions.Sender]: {
      order: 30,
      value: GeneratedFeedbackSheetItemAuthorTypesOptions.Sender,
      labelFunction: () => i18next.t('評価者のみ'),
    },
  });

export const FeedbackAuthorTypeFilters = (fbDict: UseDictionnaryReturn) =>
  new EnumMap({
    middle: {
      order: 20,
      value: 'middle',
      labelFunction: () => i18next.t('自分が{{wMiddleLayerAssignee}}', fbDict),
    },
    final: {
      order: 30,
      value: 'final',
      labelFunction: () => i18next.t('自分が{{wFinalLayerAssignee}}', fbDict),
    },
    sender: {
      order: 40,
      value: 'sender',
      labelFunction: () => i18next.t('自分が評価者'),
    },
    reader: {
      order: 50,
      value: 'reader',
      labelFunction: () => i18next.t('自分が{{wReadLayerAssignee}}', fbDict),
    },
    supervisor: {
      order: 60,
      value: 'supervisor',
      labelFunction: () => i18next.t('自分が{{wSuperviseLayerAssignee}}', fbDict),
    },
  });

export const UserRoles = new EnumMap({
  [GeneratedUserRoles.Owner]: {
    order: 10,
    value: 'owner',
    labelFunction: () => i18next.t('システム管理者'),
    description: i18next.t(
      'システム管理者の権限を持つユーザーは、契約・請求情報などシステムに関する重要事項を管理することができます。',
    ),
  },
  [GeneratedUserRoles.Hrp]: {
    order: 20,
    value: 'hrp', // (HR Professional)
    labelFunction: () => i18next.t('HR管理者'),
    description: i18next.t(
      'HR管理者の権限を持つユーザーは、ユーザー招待や権限の変更などのユーザー管理ができます。また、1on1のペア設定や、レポート結果の分析の閲覧など1on1の運用改善についての権限を持ちます。',
    ),
  },
  [GeneratedUserRoles.AccountManagement]: {
    order: 21,
    value: 'accountManagement',
    labelFunction: () => i18next.t('アカウント管理権限'),
    description: 'アカウント管理権限',
  },
  [GeneratedUserRoles.HrManagement]: {
    order: 22,
    value: 'hrManagement',
    labelFunction: () => i18next.t('管理・分析権限'),
    description: '管理・分析権限',
  },
  [GeneratedUserRoles.HrDataExport]: {
    order: 23,
    value: 'hrDataExport',
    labelFunction: () => i18next.t('書き出し権限'),
    description: '書き出し権限',
  },
  [GeneratedUserRoles.HrSetting]: {
    order: 24,
    value: 'hrSetting',
    labelFunction: () => i18next.t('設定権限'),
    description: '設定権限',
  },
  [GeneratedUserRoles.Manager]: {
    order: 30,
    value: 'manager',
    labelFunction: () => i18next.t('マネージャー'),
    description: '',
  },
  [GeneratedUserRoles.Member]: {
    order: 40,
    value: 'member',
    labelFunction: () => i18next.t('ユーザー'),
    description: i18next.t(
      '1on1を実施したり、目標を閲覧・編集することができます。この権限を持たないユーザーは、1on1・目標管理の機能を使うことができません。',
    ),
  },
});

export const HrRoles = new EnumMap({
  [GeneratedUserRoles.AccountManagement]: {
    order: 21,
    value: 'accountManagement',
    labelFunction: () => i18next.t('アカウント管理'),
    description: 'アカウント管理権限',
  },
  [GeneratedUserRoles.HrManagement]: {
    order: 22,
    value: 'hrManagement',
    labelFunction: () => i18next.t('管理・分析'),
    description: '管理・分析権限',
  },
  [GeneratedUserRoles.HrDataExport]: {
    order: 23,
    value: 'hrDataExport',
    labelFunction: () => i18next.t('書き出し'),
    description: '書き出し権限',
  },
  [GeneratedUserRoles.HrSetting]: {
    order: 24,
    value: 'hrSetting',
    labelFunction: () => i18next.t('設定'),
    description: '設定権限',
  },
});

export const SettableUserRoles = new EnumMap({
  [GeneratedUserRoles.Owner]: {
    order: 10,
    value: 'owner',
    labelFunction: () => i18next.t('システム管理者'),
    description: i18next.t(
      'システム管理者の権限を持つユーザーは、契約・請求情報などシステムに関する重要事項を管理することができます。',
    ),
  },
  [GeneratedUserRoles.Hrp]: {
    order: 20,
    value: 'hrp', // (HR Professional)
    labelFunction: () => i18next.t('HR管理者'),
    description: i18next.t(
      'HR管理者の権限を持つユーザーは、ユーザー招待や権限の変更などのユーザー管理ができます。また、1on1のペア設定や、レポート結果の分析の閲覧など1on1の運用改善についての権限を持ちます。',
    ),
  },
  [GeneratedUserRoles.Member]: {
    order: 30,
    value: 'member',
    labelFunction: () => i18next.t('ユーザー'),
    description: i18next.t(
      '1on1を実施したり、目標を閲覧・編集することができます。この権限を持たないユーザーは、1on1・目標管理の機能を使うことができません。',
    ),
  },
});

/**
 * フィードバックシートフォームのステータス
 */
export const FeedbackSheetFormStatuses = new EnumMap({
  [GeneratedFeedbackSheetFormStatuses.Draft]: {
    order: 10,
    value: GeneratedFeedbackSheetFormStatuses.Draft,
    labelFunction: () => i18next.t('ドラフト'),
  },
  [GeneratedFeedbackSheetFormStatuses.Published]: {
    order: 20,
    value: GeneratedFeedbackSheetFormStatuses.Published,
    labelFunction: () => i18next.t('公開済'),
  },
  [GeneratedFeedbackSheetFormStatuses.Deleted]: {
    order: 30,
    value: GeneratedFeedbackSheetFormStatuses.Deleted,
    labelFunction: () => i18next.t('削除済み'),
  },
  [GeneratedFeedbackSheetFormStatuses.Invalid]: {
    order: 40,
    value: GeneratedFeedbackSheetFormStatuses.Invalid,
    labelFunction: () => i18next.t('無効（親エンティティが削除された）'),
  },
});

export const PaymentTypes = new EnumMap({
  [GeneratedPaymentTypes.Card]: {
    order: 100,
    value: 'card',
    labelFunction: () => i18next.t('クレジットカード決済'),
  },
  [GeneratedPaymentTypes.Bill]: {
    order: 200,
    value: 'bill',
    labelFunction: () => i18next.t('請求書発行'),
  },
});

export const CompanyStatuses = new EnumMap({
  [GeneratedCompanyStatuses.Requested]: {
    order: 10,
    value: GeneratedCompanyStatuses.Requested,
    labelFunction: () => i18next.t('申請中'), // ベータ登録のフローがなくなって直接登録できるようになったらこのステータスも必要になるので、予め用意しておく
  },
  [GeneratedCompanyStatuses.Approved]: {
    order: 15,
    value: GeneratedCompanyStatuses.Approved,
    labelFunction: () => i18next.t('承認済'),
  },
  [GeneratedCompanyStatuses.Activated]: {
    order: 20,
    value: GeneratedCompanyStatuses.Activated,
    labelFunction: () => i18next.t('無料トライアル中'), // ベータ登録から承認されたらこのステータスになる
  },
  [GeneratedCompanyStatuses.Expired]: {
    order: 30,
    value: GeneratedCompanyStatuses.Expired,
    labelFunction: () => i18next.t('無料トライアル終了'),
  },
  [GeneratedCompanyStatuses.Registered]: {
    order: 40,
    value: GeneratedCompanyStatuses.Registered,
    labelFunction: () => i18next.t('課金申し込み済み'),
  },
  [GeneratedCompanyStatuses.Unpaid]: {
    order: 50,
    value: GeneratedCompanyStatuses.Unpaid,
    labelFunction: () => i18next.t('未払いの決済あり'),
  },
  [GeneratedCompanyStatuses.Suspended]: {
    order: 60,
    value: GeneratedCompanyStatuses.Suspended,
    labelFunction: () => i18next.t('一時利用停止中'),
  },
  [GeneratedCompanyStatuses.ResignReserved]: {
    order: 80,
    value: GeneratedCompanyStatuses.ResignReserved,
    labelFunction: () => i18next.t('退会予定'),
  },
  [GeneratedCompanyStatuses.Resigned]: {
    order: 90,
    value: GeneratedCompanyStatuses.Resigned,
    labelFunction: () => i18next.t('退会済み'),
  },
  [GeneratedCompanyStatuses.WillDiscard]: {
    order: 910,
    value: GeneratedCompanyStatuses.WillDiscard,
    labelFunction: () => i18next.t('破棄対象'),
  },
  [GeneratedCompanyStatuses.Discarded]: {
    order: 990,
    value: GeneratedCompanyStatuses.Discarded,
    labelFunction: () => i18next.t('破棄済み'),
  },
});

export const ReportLineFilters = new EnumMap({
  member: {
    order: 10,
    value: 'member',
    labelFunction: () => i18next.t('メンバーごとに表示'),
  },
  manager: {
    order: 20,
    value: 'manager',
    labelFunction: () => i18next.t('マネージャーごとに表示'),
  },
});

export const CompanyChatworkNotificationFlag = new EnumMap({
  [GeneratedCompanyChatworkNotificationFlag.Active]: {
    order: 10,
    value: 'active',
    labelFunction: () => i18next.t('通知する'),
  },
  [GeneratedCompanyChatworkNotificationFlag.Disabled]: {
    order: 20,
    value: 'disabled',
    labelFunction: () => i18next.t('通知しない'),
  },
  [GeneratedCompanyChatworkNotificationFlag.Removed]: {
    order: 30,
    value: 'removed',
    labelFunction: () => i18next.t('連携を解除する'),
  },
});

export const CompanyHrmosNotificationFlag = new EnumMap({
  [CompanyIntegrationFlag.Active]: {
    order: 10,
    value: 'active',
    labelFunction: () => i18next.t('連携する'),
  },
  [CompanyIntegrationFlag.Removed]: {
    order: 30,
    value: 'removed',
    labelFunction: () => i18next.t('連携を解除する'),
  },
});

export const CompanySlackNotificationFlag = new EnumMap({
  [GeneratedCompanySlackNotificationFlag.Active]: {
    order: 10,
    value: GeneratedCompanySlackNotificationFlag.Active,
    labelFunction: () => i18next.t('通知する'),
  },
  [GeneratedCompanySlackNotificationFlag.Disabled]: {
    order: 20,
    value: GeneratedCompanySlackNotificationFlag.Disabled,
    labelFunction: () => i18next.t('通知しない'),
  },
  [GeneratedCompanySlackNotificationFlag.Removed]: {
    order: 30,
    value: GeneratedCompanySlackNotificationFlag.Removed,
    labelFunction: () => i18next.t('連携を解除する'),
  },
});

export const CompanyTalknoteNotificationFlag = CompanySlackNotificationFlag;

export const CompanyMicrosoftTeamsNotificationFlag = CompanySlackNotificationFlag;

export const AccountIntegrationsGoogleCalendarFlag = new EnumMap({
  [UserGoogleCalendarStatus.Active]: {
    order: 10,
    value: UserGoogleCalendarStatus.Active,
    labelFunction: () => i18next.t('スケジュール同期する'),
  },
  [UserGoogleCalendarStatus.Disabled]: {
    order: 20,
    value: UserGoogleCalendarStatus.Disabled,
    labelFunction: () => i18next.t('スケジュール同期しない'),
  },
  removed: {
    order: 30,
    value: 'removed',
    labelFunction: () => i18next.t('連携を解除する'),
  },
});

export const UserStatusesOptions = new EnumMap({
  [GeneratedUserStatuses.Invited]: {
    order: 20,
    value: GeneratedUserStatuses.Invited,
    labelFunction: () => i18next.t('招待中'),
  },
  [GeneratedUserStatuses.Registered]: {
    order: 30,
    value: GeneratedUserStatuses.Registered,
    labelFunction: () => i18next.t('利用中'),
  },
  [GeneratedUserStatuses.Suspended]: {
    order: 40,
    value: GeneratedUserStatuses.Suspended,
    labelFunction: () => i18next.t('停止中'),
  },
  [GeneratedUserStatuses.Canceled]: {
    order: 50,
    value: GeneratedUserStatuses.Canceled,
    labelFunction: () => i18next.t('取消済'),
  },
  [GeneratedUserStatuses.Deleted]: {
    order: 26,
    value: GeneratedUserStatuses.Deleted,
    labelFunction: () => i18next.t('削除済'),
  },
});

export const AvailableUserStatusesOptions = new EnumMap({
  [GeneratedUserStatuses.Invited]: {
    order: 20,
    value: GeneratedUserStatuses.Invited,
    labelFunction: () => i18next.t('招待中'),
  },
  [GeneratedUserStatuses.Registered]: {
    order: 30,
    value: GeneratedUserStatuses.Registered,
    labelFunction: () => i18next.t('利用中'),
  },
  [GeneratedUserStatuses.Suspended]: {
    order: 40,
    value: GeneratedUserStatuses.Suspended,
    labelFunction: () => i18next.t('停止中'),
  },
  [GeneratedUserStatuses.Deleted]: {
    order: 50,
    value: GeneratedUserStatuses.Deleted,
    labelFunction: () => i18next.t('削除済'),
  },
});

export const O3PreSurveyItemTypes = new EnumMap({
  [GeneratedO3PreSurveyItemTypes.CheckInForm]: {
    order: 10,
    value: GeneratedO3PreSurveyItemTypes.CheckInForm,
    labelFunction: () => i18next.t('ヘルスチェック'),
  },
  [GeneratedO3PreSurveyItemTypes.Keep]: {
    order: 20,
    value: GeneratedO3PreSurveyItemTypes.Keep,
    labelFunction: () => i18next.t('良かったこと'),
  },
  [GeneratedO3PreSurveyItemTypes.Problem]: {
    order: 30,
    value: GeneratedO3PreSurveyItemTypes.Problem,
    labelFunction: () => i18next.t('悪かったこと'),
  },
  [GeneratedO3PreSurveyItemTypes.Action]: {
    order: 40,
    value: GeneratedO3PreSurveyItemTypes.Action,
    labelFunction: () => i18next.t('アクション'),
  },
  [GeneratedO3PreSurveyItemTypes.Topic]: {
    order: 50,
    value: GeneratedO3PreSurveyItemTypes.Topic,
    labelFunction: () => i18next.t('トピック'),
  },
});

export const TextEditorElementOptions = new EnumMap({
  h1: {
    order: 10,
    value: 'header-one',
    label: 'H1',
  },
  h2: {
    order: 20,
    value: 'header-two',
    label: 'H2',
  },
  h3: {
    order: 30,
    value: 'header-three',
    label: 'H3',
  },
  default: {
    order: 40,
    value: 'unstyled',
    label: 'Normal',
  },
});

export const ParentOKRSettingModalSteps = (typeLabel: string) =>
  new EnumMap({
    select: {
      order: 10,
      value: 'select',
      labelFunction: () =>
        i18next.t('紐づける{{typeLabel}}を選択', {
          typeLabel,
        }),
    },
    detail: {
      order: 20,
      value: 'detail',
      labelFunction: () => i18next.t('選択した目標の詳細'),
    },
    setting: {
      order: 30,
      value: 'setting',
      labelFunction: () => i18next.t('選択した目標の詳細'),
    },
  });

export const Priorities = new EnumMap({
  [GeneratedPriorities.High]: {
    order: 10,
    value: GeneratedPriorities.High,
    labelFunction: () => i18next.t('優先度・高'),
  },
  [GeneratedPriorities.Medium]: {
    order: 20,
    value: GeneratedPriorities.Medium,
    labelFunction: () => i18next.t('優先度・中'),
  },
  [GeneratedPriorities.Low]: {
    order: 30,
    value: GeneratedPriorities.Low,
    labelFunction: () => i18next.t('優先度・低'),
  },
});

export const BadgeTypes = new EnumMap({
  [GeneratedBadgeTypes.Default]: {
    order: 10,
    value: GeneratedBadgeTypes.Default,
    labelFunction: () => i18next.t('一般的なバッジ'),
  },
  [GeneratedBadgeTypes.SupportAction]: {
    order: 20,
    value: GeneratedBadgeTypes.SupportAction,
    labelFunction: () => i18next.t('サポート'),
  },
  [GeneratedBadgeTypes.Feedback]: {
    order: 30,
    value: GeneratedBadgeTypes.Feedback,
    labelFunction: () => i18next.t('フィードバック'),
  },
});

export const genGuidelineTypes = (dict: UseDictionnaryReturn) => {
  return new EnumMap({
    [GeneratedGuidelineTypes.Objective]: {
      order: 10,
      value: GeneratedGuidelineTypes.Objective,
      labelFunction: () => i18next.t('目標設定ガイド'),
    },

    [GeneratedGuidelineTypes.Mentee]: {
      order: 20,
      value: GeneratedGuidelineTypes.Mentee,
      labelFunction: () => i18next.t('1on1ガイド（{{wMentee}}向け）', dict),
    },
    [GeneratedGuidelineTypes.Mentor]: {
      order: 30,
      value: GeneratedGuidelineTypes.Mentor,
      labelFunction: () => i18next.t('1on1ガイド（{{wMentor}}向け）', dict),
    },
  });
};

export const ObjectiveFilters = new EnumMap({
  member: {
    order: 10,
    value: 'member',
    labelFunction: () => i18next.t('メンバー'),
    icon: 'Member',
  },
  descAchievementRate: {
    order: 20,
    value: 'descAchievementRate',
    labelFunction: () => i18next.t('達成度（高スコア順）'),
    icon: 'DescOrder',
  },
  ascAchievementRate: {
    order: 30,
    value: 'ascAchievementRate',
    labelFunction: () => i18next.t('達成度（低スコア順）'),
    icon: 'AscOrder',
  },
  descUpdatedAt: {
    order: 40,
    value: 'descUpdatedAt',
    labelFunction: () => i18next.t('更新日時（新しい順）'),
    icon: 'DescOrder',
  },
  ascUpdatedAt: {
    order: 50,
    value: 'ascUpdatedAt',
    labelFunction: () => i18next.t('更新日時（古い順）'),
    icon: 'AscOrder',
  },
});

export const genPeopleManagementMeetingFilterOptions = (dict: UseDictionnaryReturn) => {
  return new EnumMap({
    mentee: {
      order: 10,
      value: 'mentee',
      labelFunction: () => i18next.t('{{wMentee}}ごとに表示', dict),
    },
    schedule: {
      order: 20,
      value: 'schedule',
      labelFunction: () => i18next.t('スケジュールごとに表示'),
    },
  });
};

export const FeedbackPublicationTypesOptions = (fbDict: UseDictionnaryReturn) =>
  new EnumMap({
    [FeedbackPublicationTypes.Manual]: {
      order: 10,
      value: FeedbackPublicationTypes.Manual,
      labelFunction: () =>
        i18next.t('{{wFinalLayerAssignee}}が「公開」ボタンを押して公開する', fbDict),
    },
    [FeedbackPublicationTypes.Auto]: {
      order: 20,
      value: FeedbackPublicationTypes.Auto,
      labelFunction: () => i18next.t('{{wFinalLayer}}の提出時に自動で公開する', fbDict),
    },
  });

export const FeedbackSenderPublicationRangesOptions = new EnumMap({
  none: {
    order: 10,
    value: 'none',
    labelFunction: () => i18next.t('共有されない'),
  },
  hierarchy: {
    order: 20,
    value: 'hierarchy',
    labelFunction: () => i18next.t('自分より「後」に提出する評価者に共有される'),
  },
  all: {
    order: 30,
    value: 'all',
    labelFunction: () => i18next.t('すべての評価者に共有される'),
  },
});

export const FeedbackMiddleLayerTypesItems = (fbDict: UseDictionnaryReturn) =>
  new EnumMap({
    [FeedbackMiddleLayerTypes.Serial]: {
      order: 10,
      value: FeedbackMiddleLayerTypes.Serial,
      labelFunction: () =>
        i18next.t(
          '{{wMiddleLayerAssignee}}による多段階フィードバック（最大3段階）を実施する',
          fbDict,
        ),
    },
    [FeedbackMiddleLayerTypes.Parallel]: {
      order: 20,
      value: FeedbackMiddleLayerTypes.Parallel,
      labelFunction: () =>
        i18next.t('{{wMiddleLayerAssignee}}による多面評価（360FB）を実施する', fbDict),
    },
  });

export const HrCheckInStatusFilterOptions = new EnumMap({
  descAnsweredAt: {
    order: 10,
    value: 'descAnsweredAt',
    labelFunction: () => i18next.t('回答日（新しい順）'),
  },
  ascAnsweredAt: {
    order: 20,
    value: 'ascAnsweredAt',
    labelFunction: () => i18next.t('回答日（古い順）'),
  },
  descCondition: {
    order: 30,
    value: 'descCondition',
    labelFunction: () => i18next.t('目標の進み具合（高スコア順）'),
  },
  ascCondition: {
    order: 40,
    value: 'ascCondition',
    labelFunction: () => i18next.t('目標の進み具合（低スコア順）'),
  },
  descActionScore: {
    order: 50,
    value: 'descActionScore',
    labelFunction: () => i18next.t('アクション（高スコア順）'),
  },
  ascActionScore: {
    order: 60,
    value: 'ascActionScore',
    labelFunction: () => i18next.t('アクション（低スコア順）'),
  },
  descCommunicationScore: {
    order: 70,
    value: 'descCommunicationScore',
    labelFunction: () => i18next.t('コミュニケーション（高スコア順）'),
  },
  ascCommunicationScore: {
    order: 80,
    value: 'ascCommunicationScore',
    labelFunction: () => i18next.t('コミュニケーション（低スコア順）'),
  },
});

export const ObjectiveUpdateRateFilterOptions = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    descObjectiveUpdatedCount: {
      order: 10,
      value: 'descObjectiveUpdatedCount',
      labelFunction: () => i18next.t('1ヶ月以内の更新回数（多い順）'),
    },
    ascObjectiveUpdatedCount: {
      order: 20,
      value: 'ascObjectiveUpdatedCount',
      labelFunction: () => i18next.t('1ヶ月以内の更新回数（少ない順）'),
    },
    descCheckInLastAnsweredAt: {
      order: 30,
      value: 'descCheckInLastAnsweredAt',
      labelFunction: () => i18next.t('{{wCheckIn}}の最終回答日（新しい順）', dict),
    },
    ascCheckInLastAnsweredAt: {
      order: 40,
      value: 'ascCheckInLastAnsweredAt',
      labelFunction: () => i18next.t('{{wCheckIn}}の最終回答日（古い順）', dict),
    },
  });

export const HrDashboardCompareSortOptions = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    ascTeam: {
      order: 10,
      value: 'ascTeam',
      labelFunction: () => i18next.t('{{wTeam}}（昇順）', dict),
    },
    descTeam: {
      order: 20,
      value: 'descTeam',
      labelFunction: () => i18next.t('{{wTeam}}（降順）', dict),
    },
    descCondition: {
      order: 30,
      value: 'descCondition',
      labelFunction: () => i18next.t('目標の進み具合（高スコア順）'),
    },
    ascCondition: {
      order: 40,
      value: 'ascCondition',
      labelFunction: () => i18next.t('目標の進み具合（低スコア順）'),
    },
    descObjectiveAchievementRate: {
      order: 50,
      value: 'descObjectiveAchievementRate',
      labelFunction: () => i18next.t('目標の達成度（高スコア順）'),
    },
    ascObjectiveAchievementRate: {
      order: 60,
      value: 'ascObjectiveAchievementRate',
      labelFunction: () => i18next.t('目標の達成度（低スコア順）'),
    },
    descObjectiveUpdateRate: {
      order: 70,
      value: 'descObjectiveUpdateRate',
      labelFunction: () => i18next.t('目標の更新率（高スコア順）'),
    },
    ascObjectiveUpdateRate: {
      order: 80,
      value: 'ascObjectiveUpdateRate',
      labelFunction: () => i18next.t('目標の更新率（低スコア順）'),
    },
    descCheckInActionScore: {
      order: 90,
      value: 'descCheckInActionScore',
      labelFunction: () => i18next.t('次アクション（高スコア順）'),
    },
    ascCheckInActionScore: {
      order: 100,
      value: 'ascCheckInActionScore',
      labelFunction: () => i18next.t('次アクション（低スコア順）'),
    },
    descCheckInCommunicationScore: {
      order: 110,
      value: 'descCheckInCommunicationScore',
      labelFunction: () => i18next.t('コミュニケーション（高スコア順）'),
    },
    ascCheckInCommunicationScore: {
      order: 120,
      value: 'ascCheckInCommunicationScore',
      labelFunction: () => i18next.t('コミュニケーション（低スコア順）'),
    },
    descMeetingDoneRate: {
      order: 130,
      value: 'descMeetingDoneRate',
      labelFunction: () => i18next.t('1on1実施率（高スコア順）'),
    },
    ascMeetingDoneRate: {
      order: 140,
      value: 'ascMeetingDoneRate',
      labelFunction: () => i18next.t('1on1実施率（低スコア順）'),
    },
  });

export const CheckInActionScoreStatuses = new EnumMap({
  '1': {
    order: 10,
    value: 'veryBad',
    labelFunction: () => i18next.t('明確ではない'),
  },
  '2': {
    order: 20,
    value: 'bad',
    labelFunction: () => i18next.t('あまり明確ではない'),
  },
  '3': {
    order: 30,
    value: 'normal',
    labelFunction: () => i18next.t('普通'),
  },
  '4': {
    order: 40,
    value: 'good',
    labelFunction: () => i18next.t('まあまあ明確である'),
  },
  '5': {
    order: 50,
    value: 'veryGood',
    labelFunction: () => i18next.t('明確である'),
  },
});

export const CheckInCommunicationScoreStatuses = new EnumMap({
  '1': {
    order: 10,
    value: 'veryBad',
    labelFunction: () => i18next.t('十分ではない'),
  },
  '2': {
    order: 20,
    value: 'bad',
    labelFunction: () => i18next.t('あまり十分ではない'),
  },
  '3': {
    order: 30,
    value: 'normal',
    labelFunction: () => i18next.t('普通'),
  },
  '4': {
    order: 40,
    value: 'good',
    labelFunction: () => i18next.t('まあまあ十分である'),
  },
  '5': {
    order: 50,
    value: 'veryGood',
    labelFunction: () => i18next.t('十分である'),
  },
});

export const BetaEntryStatusesEnum = new EnumMap({
  [BetaEntryStatuses.SimpleEntry]: {
    order: 10,
    value: BetaEntryStatuses.SimpleEntry,
    labelFunction: () => i18next.t('LPからの申込'),
  },
  [BetaEntryStatuses.Requested]: {
    order: 20,
    value: BetaEntryStatuses.Requested,
    labelFunction: () => i18next.t('申請中'),
  },
  [BetaEntryStatuses.Approved]: {
    order: 80,
    value: BetaEntryStatuses.Approved,
    labelFunction: () => i18next.t('承認済'),
  },
  [BetaEntryStatuses.Entered]: {
    order: 90,
    value: BetaEntryStatuses.Entered,
    labelFunction: () => i18next.t('自動承認済'),
  },
  [BetaEntryStatuses.Discarded]: {
    order: 990,
    value: BetaEntryStatuses.Discarded,
    labelFunction: () => i18next.t('破棄済み'),
  },
});

export const ManagementIndicatorTypes = new EnumMap({
  [GeneratedManagementIndicatorTypes.CheckInCondition]: {
    order: 10,
    value: GeneratedManagementIndicatorTypes.CheckInCondition,
    labelFunction: () => i18next.t('目標の進み具合（セルフチェック）'),
  },
  [GeneratedManagementIndicatorTypes.ObjectiveAchievementRate]: {
    order: 20,
    value: GeneratedManagementIndicatorTypes.ObjectiveAchievementRate,
    labelFunction: () => i18next.t('目標の達成度'),
  },
  [GeneratedManagementIndicatorTypes.ObjectiveUpdateRate]: {
    order: 30,
    value: GeneratedManagementIndicatorTypes.ObjectiveUpdateRate,
    labelFunction: () => i18next.t('目標の更新率'),
  },
  [GeneratedManagementIndicatorTypes.CheckInActionScore]: {
    order: 40,
    value: GeneratedManagementIndicatorTypes.CheckInActionScore,
    labelFunction: () => i18next.t('目標達成に向けた次アクション'),
  },
  [GeneratedManagementIndicatorTypes.CheckInCommunicationScore]: {
    order: 50,
    value: GeneratedManagementIndicatorTypes.CheckInCommunicationScore,
    labelFunction: () => i18next.t('目標達成のためのコミュニケーション'),
  },
  [GeneratedManagementIndicatorTypes.O3MeetingDoneRate]: {
    order: 60,
    value: GeneratedManagementIndicatorTypes.O3MeetingDoneRate,
    labelFunction: () => i18next.t('1on1の実施率'),
  },
});

export const ManagementPriorities = new EnumMap({
  [GeneratedPriorities.High]: {
    order: 10,
    value: GeneratedPriorities.High,
    labelFunction: () => i18next.t('改善が必要'),
  },
  [GeneratedPriorities.Medium]: {
    order: 20,
    value: GeneratedPriorities.Medium,
    labelFunction: () => i18next.t('平均的'),
  },
  [GeneratedPriorities.Low]: {
    order: 30,
    value: GeneratedPriorities.Low,
    labelFunction: () => i18next.t('理想的'),
  },
});

export const genO3sSortOptionsByFilter = (dict: UseDictionnaryReturn, filterId: string) => {
  if (filterId === 'mentor') {
    return new EnumMap({
      descMentor: {
        order: 10,
        value: 'mentor',
        labelFunction: () => i18next.t('{{wMentor}}（降順）', dict),
        orderBy: 'desc',
      },
      ascMentor: {
        order: 20,
        value: 'mentor',
        labelFunction: () => i18next.t('{{wMentor}}（昇順）', dict),
        orderBy: 'asc',
      },
    });
  }

  if (filterId === 'mentee') {
    return new EnumMap({
      descMentee: {
        order: 30,
        value: 'mentee',
        labelFunction: () => i18next.t('{{wMentee}}（降順）', dict),
        orderBy: 'desc',
      },
      ascMentee: {
        order: 40,
        value: 'mentee',
        labelFunction: () => i18next.t('{{wMentee}}（昇順）', dict),
        orderBy: 'asc',
      },
    });
  }

  if (filterId === 'team') {
    return new EnumMap({
      descTeam: {
        order: 50,
        value: 'team',
        labelFunction: () => i18next.t('{{wTeam}}（降順）', dict),
        orderBy: 'desc',
      },
      ascTeam: {
        order: 60,
        value: 'team',
        labelFunction: () => i18next.t('{{wTeam}}（昇順）', dict),
        orderBy: 'asc',
      },
    });
  }
  return new EnumMap({});
};

export const O3sStatusSortCommonOptions = new EnumMap({
  descLastDoneMeeting: {
    order: 70,
    value: 'lastDoneMeeting',
    labelFunction: () => i18next.t('最終実施日（新しい順）'),
    orderBy: 'desc',
  },
  ascLastDoneMeeting: {
    order: 80,
    value: 'lastDoneMeeting',
    labelFunction: () => i18next.t('最終実施日（古い順）'),
    orderBy: 'asc',
  },
  descStatus: {
    order: 90,
    value: 'status',
    labelFunction: () => i18next.t('実施状況（多い順）'),
    orderBy: 'desc',
  },
  ascStatus: {
    order: 100,
    value: 'status',
    labelFunction: () => i18next.t('実施状況（少ない順）'),
    orderBy: 'asc',
  },
  descNoPreSurvey: {
    order: 110,
    value: 'noPreSurvey',
    labelFunction: () => i18next.t('事前アンケート未提出（多い順）'),
    orderBy: 'desc',
  },
  ascNoPreSurvey: {
    order: 120,
    value: 'noPreSurvey',
    labelFunction: () => i18next.t('事前アンケート未提出（少ない順）'),
    orderBy: 'asc',
  },
});

export const O3sFulfillingSortOptions = new EnumMap({
  descVeryFulfilling: {
    order: 10,
    value: 'veryFulfilling',
    labelFunction: () => i18next.t('とても充実している（高スコア順）'),
    orderBy: 'desc',
  },
  ascVeryFulfilling: {
    order: 20,
    value: 'veryFulfilling',
    labelFunction: () => i18next.t('とても充実している（低スコア順）'),
    orderBy: 'asc',
  },
  descFulfilling: {
    order: 30,
    value: 'fulfilling',
    labelFunction: () => i18next.t('充実している（高スコア順）'),
    orderBy: 'desc',
  },
  ascFulfilling: {
    order: 40,
    value: 'fulfilling',
    labelFunction: () => i18next.t('充実している（低スコア順）'),
    orderBy: 'asc',
  },
  descNotFulfilling: {
    order: 50,
    value: 'notFulfilling',
    labelFunction: () => i18next.t('あまり充実していない（高スコア順）'),
    orderBy: 'desc',
  },
  ascNotFulfilling: {
    order: 60,
    value: 'notFulfilling',
    labelFunction: () => i18next.t('あまり充実していない（低スコア順）'),
    orderBy: 'asc',
  },
  descVeryNotFulfilling: {
    order: 70,
    value: 'veryNotFulfilling',
    labelFunction: () => i18next.t('充実していない（高スコア順）'),
    orderBy: 'desc',
  },
  ascVeryNotFulfilling: {
    order: 80,
    value: 'veryNotFulfilling',
    labelFunction: () => i18next.t('充実していない（低スコア順）'),
    orderBy: 'asc',
  },
});

export const CompanyIpRestrictionPolicyOptions = new EnumMap({
  allowAll: {
    order: 10,
    value: CompanyIpRestrictionPolicies.AllowAll,
    labelFunction: () => i18next.t('すべて許可'),
  },
  whiteList: {
    order: 20,
    value: CompanyIpRestrictionPolicies.Whitelist,
    labelFunction: () => i18next.t('ホワイトリストを設定'),
  },
  blackList: {
    order: 30,
    value: CompanyIpRestrictionPolicies.Blacklist,
    labelFunction: () => i18next.t('ブラックリストを設定'),
  },
});

export const FeedbackReceiverFilterIncludes = new EnumMap({
  [GeneratedFeedbackReceiverFilterComparisonOperatorTypes.In]: {
    order: 10,
    value: GeneratedFeedbackReceiverFilterComparisonOperatorTypes.In,
    labelFunction: () => `${i18next.t('に属する')}`,
  },
  [GeneratedFeedbackReceiverFilterComparisonOperatorTypes.ParentIn]: {
    order: 20,
    value: GeneratedFeedbackReceiverFilterComparisonOperatorTypes.ParentIn,
    labelFunction: () => `${i18next.t('に属する')} (${i18next.t('子要素を含む')})`,
  },
  [GeneratedFeedbackReceiverFilterComparisonOperatorTypes.NotIn]: {
    order: 30,
    value: GeneratedFeedbackReceiverFilterComparisonOperatorTypes.NotIn,
    labelFunction: () => i18next.t('に属さない'),
  },
});

export const Languages = new EnumMap({
  ja: {
    order: 10,
    value: 'ja',
    labelFunction: () => i18next.t('日本語'),
  },
  en: {
    order: 10,
    value: 'en',
    labelFunction: () => i18next.t('English'),
  },
});

export const ObjectiveSortKeys = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    [GeneratedObjectiveSortKeys.OwnerId]: {
      order: 10,
      value: GeneratedObjectiveSortKeys.OwnerId,
      labelFunction: () => i18next.t('担当者'),
    },
    [GeneratedObjectiveSortKeys.CategoryId]: {
      order: 20,
      value: GeneratedObjectiveSortKeys.CategoryId,
      labelFunction: () => i18next.t('カテゴリー'),
    },
    [GeneratedObjectiveSortKeys.SourceType]: {
      order: 30,
      value: GeneratedObjectiveSortKeys.SourceType,
      labelFunction: () => i18next.t('種類（個人／{{wTeam}}／会社）', dict),
    },
    [GeneratedObjectiveSortKeys.Weight]: {
      order: 40,
      value: GeneratedObjectiveSortKeys.Weight,
      labelFunction: () => i18next.t('重要度'),
    },
    [GeneratedObjectiveSortKeys.Title]: {
      order: 50,
      value: GeneratedObjectiveSortKeys.Title,
      labelFunction: () => i18next.t('タイトル'),
    },
    [GeneratedObjectiveSortKeys.AchievementRate]: {
      order: 60,
      value: GeneratedObjectiveSortKeys.AchievementRate,
      labelFunction: () => i18next.t('達成度'),
    },
    [GeneratedObjectiveSortKeys.StartAt]: {
      order: 70,
      value: GeneratedObjectiveSortKeys.StartAt,
      labelFunction: () => i18next.t('開始日時'),
    },
    [GeneratedObjectiveSortKeys.EndAt]: {
      order: 70,
      value: GeneratedObjectiveSortKeys.EndAt,
      labelFunction: () => i18next.t('終了日時'),
    },
  });

export const ManagementObjectiveSortKeys = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    descAchievementRate: {
      order: 10,
      value: 'descAchievementRate',
      labelFunction: () => i18next.t('達成度（高い順）'),
      icon: 'DescOrder',
    },
    ascAchievementRate: {
      order: 20,
      value: 'ascAchievementRate',
      labelFunction: () => i18next.t('達成度（低い順）'),
      icon: 'AscOrder',
    },
    descWeight: {
      order: 30,
      value: 'descWeight',
      labelFunction: () => i18next.t('重要度（高い順）'),
      icon: 'DescOrder',
    },
    ascWeight: {
      order: 40,
      value: 'ascWeight',
      labelFunction: () => i18next.t('重要度（低い順）'),
      icon: 'AscOrder',
    },
    descAchievementRateUpdatedAt: {
      order: 50,
      value: 'descAchievementRateUpdatedAt',
      labelFunction: () => i18next.t('更新日時（新しい順）'),
      icon: 'DescOrder',
    },
    ascAchievementRateUpdatedAt: {
      order: 60,
      value: 'ascAchievementRateUpdatedAt',
      labelFunction: () => i18next.t('更新日時（古い順）'),
      icon: 'AscOrder',
    },
    // descIndicatorUpdatedAt: {
    //   order: 50,
    //   value: 'descIndicatorUpdatedAt',
    //   labelFunction: () => i18next.t('更新日時（新しい順）'),
    //   icon: 'DescOrder',
    // },
    // ascIndicatorUpdatedAt: {
    //   order: 60,
    //   value: 'ascIndicatorUpdatedAt',
    //   labelFunction: () => i18next.t('更新日時（古い順）'),
    //   icon: 'AscOrder',
    // },
    ascSourceType: {
      order: 70,
      value: 'ascSourceType',
      labelFunction: () => i18next.t('種類（{{wTeam}}／個人）', dict),
      icon: 'AscOrder',
    },
    descSourceType: {
      order: 80,
      value: 'descSourceType',
      labelFunction: () => i18next.t('種類（個人／{{wTeam}}）', dict),
      icon: 'DescOrder',
    },
  });

export const ObjectiveSheetItemOptions = new EnumMap({
  [ObjectiveSheetItemTypes.Comment]: {
    order: 11,
    value: ObjectiveSheetItemTypes.Comment,
    labelFunction: () => i18next.t('フリーコメント'),
  },
  [ObjectiveSheetItemTypes.SingleLine]: {
    order: 12,
    value: ObjectiveSheetItemTypes.SingleLine,
    labelFunction: () => i18next.t('１行コメント'),
  },
  [`${ObjectiveSheetItemTypes.Listing}-${ObjectiveSheetItemElementTypes.SingleLine}`]: {
    order: 13,
    value: `${ObjectiveSheetItemTypes.Listing}-${ObjectiveSheetItemElementTypes.SingleLine}`,
    labelFunction: () => i18next.t('箇条書き'),
  },
  [ObjectiveSheetItemTypes.Grade]: {
    order: 21,
    value: ObjectiveSheetItemTypes.Grade,
    labelFunction: () => i18next.t('選択式（レーティング）'),
  },
  [ObjectiveSheetItemTypes.Number]: {
    order: 31,
    value: ObjectiveSheetItemTypes.Number,
    labelFunction: () => i18next.t('数値'),
  },
  [`${ObjectiveSheetItemTypes.Listing}-${ObjectiveSheetItemElementTypes.Objective}`]: {
    order: 51,
    value: `${ObjectiveSheetItemTypes.Listing}-${ObjectiveSheetItemElementTypes.Objective}`,
    labelFunction: () => i18next.t('目標（別画面で入力）'),
  },
  [`${ObjectiveSheetItemTypes.Listing}-${ObjectiveSheetItemElementTypes.ObjectiveForm}`]: {
    order: 52,
    value: `${ObjectiveSheetItemTypes.Listing}-${ObjectiveSheetItemElementTypes.ObjectiveForm}`,
    labelFunction: () => i18next.t('目標（シート上で直接入力）'),
  },
  [`${ObjectiveSheetItemTypes.Listing}-${ObjectiveSheetItemElementTypes.Section}`]: {
    order: 81,
    value: `${ObjectiveSheetItemTypes.Listing}-${ObjectiveSheetItemElementTypes.Section}`,
    labelFunction: () => i18next.t('セクション（項目グループ）'),
  },
  [ObjectiveSheetItemTypes.Property]: {
    order: 91,
    value: ObjectiveSheetItemTypes.Property,
    labelFunction: () => i18next.t('ユーザ属性'),
  },
});

export const ObjectiveSheetItemListOptions = new EnumMap({
  [ObjectiveSheetItemElementTypes.SingleLine]: {
    order: 10,
    value: ObjectiveSheetItemElementTypes.SingleLine,
    labelFunction: () => i18next.t('箇条書き'),
  },
  [ObjectiveSheetItemElementTypes.Objective]: {
    order: 20,
    value: ObjectiveSheetItemElementTypes.Objective,
    labelFunction: () => i18next.t('目標'),
  },
});

export const getObjectiveSheetPublicationRanges = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    [GeneratedObjectiveSheetPublicationRanges.Public]: {
      order: 10,
      value: GeneratedObjectiveSheetPublicationRanges.Public,
      labelFunction: () => i18next.t('パブリック（企業内の全員に公開）'),
    },
    [GeneratedObjectiveSheetPublicationRanges.Customize]: {
      order: 20,
      value: GeneratedObjectiveSheetPublicationRanges.Customize,
      labelFunction: () => i18next.t('限定公開（本人と承認者・閲覧者にのみ公開）', dict),
    },
  });

export const ConfidenceLevelOptions = new EnumMap({
  [OkrConfidenceLevels.Lv1]: {
    order: 10,
    value: OkrConfidenceLevels.Lv1,
    labelFunction: () => i18next.t('順調'),
  },
  [OkrConfidenceLevels.Lv2]: {
    order: 20,
    value: OkrConfidenceLevels.Lv2,
    labelFunction: () => i18next.t('やや順調'),
  },
  [OkrConfidenceLevels.Lv3]: {
    order: 30,
    value: OkrConfidenceLevels.Lv3,
    labelFunction: () => i18next.t('普通'),
  },
  [OkrConfidenceLevels.Lv4]: {
    order: 40,
    value: OkrConfidenceLevels.Lv4,
    labelFunction: () => i18next.t('やや不安'),
  },
  [OkrConfidenceLevels.Lv5]: {
    order: 50,
    value: OkrConfidenceLevels.Lv5,
    labelFunction: () => i18next.t('不安'),
  },
});

export const FeedbackSheetTargetObjectiveOptions = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    [FeedbackSheetTargetObjectiveTypes.All]: {
      order: 10,
      value: FeedbackSheetTargetObjectiveTypes.All,
      labelFunction: () => i18next.t('すべての目標'),
    },
    [FeedbackSheetTargetObjectiveTypes.SelectedSheetSchedule]: {
      order: 20,
      value: FeedbackSheetTargetObjectiveTypes.SelectedSheetSchedule,
      labelFunction: () => i18next.t('{{wObjectiveSheet}}を指定', dict),
    },
    [FeedbackSheetTargetObjectiveTypes.SelectedCategory]: {
      order: 30,
      value: FeedbackSheetTargetObjectiveTypes.SelectedCategory,
      labelFunction: () => i18next.t('目標カテゴリーを指定'),
    },
  });

export const ObjectiveSheetFormApprovalStatusesOptions = new EnumMap({
  [ObjectiveSheetFormApprovalStatuses.Preparing]: {
    order: 10,
    value: ObjectiveSheetFormApprovalStatuses.Preparing,
    labelFunction: () => i18next.t('未承認'),
  },
  [ObjectiveSheetFormApprovalStatuses.Canceled]: {
    order: 20,
    value: ObjectiveSheetFormApprovalStatuses.Canceled,
    labelFunction: () => i18next.t('取下げ'),
  },
  [ObjectiveSheetFormApprovalStatuses.Requested]: {
    order: 30,
    value: ObjectiveSheetFormApprovalStatuses.Requested,
    labelFunction: () => i18next.t('申請中'),
  },
  [ObjectiveSheetFormApprovalStatuses.Rejected]: {
    order: 40,
    value: ObjectiveSheetFormApprovalStatuses.Rejected,
    labelFunction: () => i18next.t('差戻し'),
  },
  [ObjectiveSheetFormApprovalStatuses.Approved]: {
    order: 50,
    value: ObjectiveSheetFormApprovalStatuses.Approved,
    labelFunction: () => i18next.t('承認済'),
  },
});

export const ObjectiveSheetFormStatusesOptions = new EnumMap({
  [ObjectiveSheetFormStatuses.Deleted]: {
    order: 10,
    value: ObjectiveSheetFormStatuses.Deleted,
    labelFunction: () => i18next.t('削除'),
  },
  [ObjectiveSheetFormStatuses.Draft]: {
    order: 20,
    value: ObjectiveSheetFormStatuses.Draft,
    labelFunction: () => i18next.t('未提出'),
  },
  [ObjectiveSheetFormStatuses.Submitted]: {
    order: 30,
    value: ObjectiveSheetFormStatuses.Submitted,
    labelFunction: () => i18next.t('提出済み'),
  },
  [ObjectiveSheetFormStatuses.Published]: {
    order: 40,
    value: ObjectiveSheetFormStatuses.Published,
    labelFunction: () => i18next.t('公開済み'),
  },
  [ObjectiveSheetFormStatuses.Inactive]: {
    order: 50,
    value: ObjectiveSheetFormStatuses.Inactive,
    labelFunction: () => i18next.t('非アクティブ'),
  },
  [ObjectiveSheetFormStatuses.Invalid]: {
    order: 60,
    value: ObjectiveSheetFormStatuses.Invalid,
    labelFunction: () => i18next.t('無効'),
  },
});

export const ObjectiveSheetFormApprovalEventOptions = new EnumMap({
  [ObjectiveSheetFormApprovalEventTypes.New]: {
    order: 10,
    value: ObjectiveSheetFormApprovalEventTypes.New,
    labelFunction: () => i18next.t('新規承認'),
  },
  [ObjectiveSheetFormApprovalEventTypes.Change]: {
    order: 20,
    value: ObjectiveSheetFormApprovalEventTypes.Change,
    labelFunction: () => i18next.t('内容変更'),
  },
});

// export const ObjectiveSheetFormApprovalOperationsOptions = new EnumMap({
//   [ObjectiveSheetFormApprovalOperations.Request]: {
//     order: 10,
//     value: ObjectiveSheetFormApprovalOperations.Request,
//     labelFunction: () => i18next.t('要請'),
//   },
//   [ObjectiveSheetFormApprovalOperations.Cancel]: {
//     order: 20,
//     value: ObjectiveSheetFormApprovalOperations.Cancel,
//     labelFunction: () => i18next.t('要請取り下げ'),
//   },
//   [ObjectiveSheetFormApprovalOperations.Reject]: {
//     order: 30,
//     value: ObjectiveSheetFormApprovalOperations.Reject,
//     labelFunction: () => i18next.t('拒否'),
//   },
//   [ObjectiveSheetFormApprovalOperations.Approve]: {
//     order: 40,
//     value: ObjectiveSheetFormApprovalOperations.Approve,
//     labelFunction: () => i18next.t('承認'),
//   },
// });

export const ObjectiveSheetFormFilterStatusesOptions = new EnumMap({
  [ObjectiveSheetFormFilterStatuses.Draft]: {
    order: 10,
    value: ObjectiveSheetFormFilterStatuses.Draft,
    labelFunction: () => i18next.t('未提出'),
  },
  // [ObjectiveSheetFormFilterStatuses.Canceled]: {
  //   order: 20,
  //   value: ObjectiveSheetFormFilterStatuses.Canceled,
  //   labelFunction: () => i18next.t('取下げ'),
  // },
  [ObjectiveSheetFormFilterStatuses.Requested]: {
    order: 30,
    value: ObjectiveSheetFormFilterStatuses.Requested,
    labelFunction: () => i18next.t('申請中'),
  },
  [ObjectiveSheetFormFilterStatuses.Rejected]: {
    order: 40,
    value: ObjectiveSheetFormFilterStatuses.Rejected,
    labelFunction: () => i18next.t('差戻し'),
  },
  [ObjectiveSheetFormFilterStatuses.Approved]: {
    order: 50,
    value: ObjectiveSheetFormFilterStatuses.Approved,
    labelFunction: () => i18next.t('承認済'),
  },
});

export const ObjectiveSheetFormFilterApprovalNotRequiredStatusesOptions = new EnumMap({
  [ObjectiveSheetFormFilterApprovalNotRequiredStatuses.Draft]: {
    order: 10,
    value: ObjectiveSheetFormFilterApprovalNotRequiredStatuses.Draft,
    labelFunction: () => i18next.t('未提出'),
  },
  [ObjectiveSheetFormFilterApprovalNotRequiredStatuses.Published]: {
    order: 20,
    value: ObjectiveSheetFormFilterApprovalNotRequiredStatuses.Published,
    labelFunction: () => i18next.t('提出済'),
  },
});

export const ObjectiveSheetFormFilterApprovalRequiredStatusesOptions = new EnumMap({
  [ObjectiveSheetFormFilterApprovalRequiredStatuses.Preparing]: {
    order: 10,
    value: ObjectiveSheetFormFilterApprovalRequiredStatuses.Preparing,
    labelFunction: () => i18next.t('未提出・取下げ'),
  },
  // [ObjectiveSheetFormFilterApprovalRequiredStatuses.Canceled]: {
  //   order: 20,
  //   value: ObjectiveSheetFormFilterApprovalRequiredStatuses.Canceled,
  //   labelFunction: () => i18next.t('取下げ'),
  // },
  [ObjectiveSheetFormFilterApprovalRequiredStatuses.Requested]: {
    order: 30,
    value: ObjectiveSheetFormFilterApprovalRequiredStatuses.Requested,
    labelFunction: () => i18next.t('申請中'),
  },
  [ObjectiveSheetFormFilterApprovalRequiredStatuses.Rejected]: {
    order: 40,
    value: ObjectiveSheetFormFilterApprovalRequiredStatuses.Rejected,
    labelFunction: () => i18next.t('差戻し'),
  },
  [ObjectiveSheetFormFilterApprovalRequiredStatuses.Approved]: {
    order: 50,
    value: ObjectiveSheetFormFilterApprovalRequiredStatuses.Approved,
    labelFunction: () => i18next.t('承認済'),
  },
});

export const getObjectiveSheetPublicationsFilters = (fbDict: UseDictionnaryReturn) =>
  new EnumMap({
    approver: {
      order: 10,
      value: 'approver',
      labelFunction: () => i18next.t('{{wObjectiveSheetApprover}}に設定されているシート', fbDict),
    },
    reader: {
      order: 20,
      value: 'reader',
      labelFunction: () => i18next.t('{{wObjectiveSheetReader}}に設定されているシート', fbDict),
    },
    onlyPublished: {
      order: 30,
      value: 'onlyPublished',
      labelFunction: () => i18next.t('公開されているすべてのシート', fbDict),
    },
  });

export const getObjectiveSheetHasApproverAndReaderSettedUpOptions = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    hasNotApprover: {
      order: 10,
      value: 'hasNotApprover',
      labelFunction: () =>
        `${i18next.t('{{wObjectiveSheetApprover}}', dict)} : ${i18next.t('未設定')}`,
    },
    hasApprover: {
      order: 20,
      value: 'hasApprover',
      labelFunction: () =>
        `${i18next.t('{{wObjectiveSheetApprover}}', dict)} : ${i18next.t('設定済み')}`,
    },
    hasNotReader: {
      order: 30,
      value: 'hasNotReader',
      labelFunction: () =>
        `${i18next.t('{{wObjectiveSheetReader}}', dict)} : ${i18next.t('未設定')}`,
    },
    hasReader: {
      order: 40,
      value: 'hasReader',
      labelFunction: () =>
        `${i18next.t('{{wObjectiveSheetReader}}', dict)} : ${i18next.t('設定済み')}`,
    },
  });

export const ObjectiveTeamCollabotationSetting = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    owner: {
      order: 10,
      value: 'owner',
      labelFunction: () => `${i18next.t('オーナーのみ')}`,
    },
    ownerWithMembers: {
      order: 20,
      value: 'ownerWithMembers',
      labelFunction: () => `${i18next.t('オーナー + {{wTeam}}メンバー', dict)}`,
    },
    all: {
      order: 30,
      value: 'all',
      labelFunction: () => `${i18next.t('すべてのユーザー', dict)}`,
    },
  });

export const DefaultEnableCustomPublicationRangeOptions = new EnumMap({
  public: {
    order: 10,
    value: false,
    labelFunction: () => `${i18next.t('変更できない（パブリックのみ）')}`,
  },
  publicWithCustom: {
    order: 20,
    value: true,
    labelFunction: () => `${i18next.t('変更できる（パブリック or 限定公開）')}`,
  },
});

export const SlackPayloadRealTimeFeedbackBlockLabel = new EnumMap({
  good: {
    order: 10,
    value: 'good',
    labelFunction: () => `${i18next.t('GOOD')}`,
  },
  more: {
    order: 20,
    value: 'more',
    labelFunction: () => `${i18next.t('MORE')}`,
  },
});

export const ObjectiveDifficultyOptions = new EnumMap({
  // [OkrDifficultyLevels.None]: {
  //   order: 10,
  //   value: OkrDifficultyLevels.None,
  //   labelFunction: () => `${i18next.t('NONE')}`,
  // },
  [OkrDifficultyLevels.Commit]: {
    order: 20,
    value: OkrDifficultyLevels.Commit,
    labelFunction: () => `${i18next.t('コミット')}`,
  },
  [OkrDifficultyLevels.Stretch]: {
    order: 30,
    value: OkrDifficultyLevels.Stretch,
    labelFunction: () => `${i18next.t('ストレッチ')}`,
  },
});

export const MemberBordOrders = new EnumMap({
  // [ManagementSortKeys.Id]: {
  //   order: 10,
  //   value: ManagementSortKeys.Id,
  //   labelFunction: () => i18next.t('デフォルト'),
  // },
  [ManagementSortKeys.Name]: {
    order: 20,
    value: ManagementSortKeys.Name,
    labelFunction: () => i18next.t('氏名'),
  },
  [ManagementSortKeys.EmployeeNumber]: {
    order: 30,
    value: ManagementSortKeys.EmployeeNumber,
    labelFunction: () => i18next.t('社員番号'),
  },
  [ManagementSortKeys.Priority]: {
    order: 40,
    value: ManagementSortKeys.Priority,
    labelFunction: () => i18next.t('優先度・高'),
  },
  // TODO
  // [ManagementSortKeys.AchievementRate]: {
  //   order: 50,
  //   value: ManagementSortKeys.AchievementRate,
  //   labelFunction: () => i18next.t('目標達成率'),
  // },
});

export const FeedbackTemplateRequestSettingsOptions = new EnumMap({
  disableRequest: {
    order: 10,
    value: false,
    labelFunction: () => i18next.t('項目を指定しない'),
  },
  enableRequest: {
    order: 10,
    value: true,
    labelFunction: () => i18next.t('項目を指定する'),
  },
});

export enum DisplayFullFillItemEnum {
  DisplayNotData = 'displayNotData',
  DisplayVeryNotFullFilling = 'displayVeryNotFullFilling',
  DisplayNotFullFilling = 'displayNotFullFilling',
  DisplayFulfilling = 'displayFulfilling',
  DisplayVeryFullFilling = 'displayVeryFullFilling',
}

export const DisplayFullFillItemOptions = new EnumMap({
  [DisplayFullFillItemEnum.DisplayNotData]: {
    order: 10,
    value: DisplayFullFillItemEnum.DisplayNotData,
    labelFunction: () => i18next.t('データがない1on1を表示'),
  },
  [DisplayFullFillItemEnum.DisplayVeryNotFullFilling]: {
    order: 10,
    value: DisplayFullFillItemEnum.DisplayVeryNotFullFilling,
    labelFunction: () => i18next.t('「充実していない」を表示'),
  },
  [DisplayFullFillItemEnum.DisplayNotFullFilling]: {
    order: 20,
    value: DisplayFullFillItemEnum.DisplayNotFullFilling,
    labelFunction: () => i18next.t('「あまり充実していない」を表示'),
  },
  [DisplayFullFillItemEnum.DisplayFulfilling]: {
    order: 30,
    value: DisplayFullFillItemEnum.DisplayFulfilling,
    labelFunction: () => i18next.t('「充実している」を表示'),
  },
  [DisplayFullFillItemEnum.DisplayVeryFullFilling]: {
    order: 40,
    value: DisplayFullFillItemEnum.DisplayVeryFullFilling,
    labelFunction: () => i18next.t('「とても充実している」を表示'),
  },
});

export const ObjectiveSheetItemAuthorTypesOptions = new EnumMap({
  [ObjectiveSheetItemAuthorTypes.Person]: {
    order: 10,
    value: ObjectiveSheetItemAuthorTypes.Person,
    labelFunction: () => i18next.t('対象者'),
  },
  [GeneratedFeedbackSheetItemAuthorTypesOptions.Sender]: {
    order: 20,
    value: ObjectiveSheetItemAuthorTypes.Approver,
    labelFunction: () => i18next.t('承認者'),
  },
});

export const ImportProcessStatusOptions = new EnumMap({
  [ImportProcessStatus.Doing]: {
    order: 10,
    value: ImportProcessStatus.Doing,
    labelFunction: () => i18next.t('実行中'),
  },
  [ImportProcessStatus.Error]: {
    order: 10,
    value: ImportProcessStatus.Error,
    labelFunction: () => i18next.t('エラー'),
  },
  [ImportProcessStatus.Success]: {
    order: 10,
    value: ImportProcessStatus.Success,
    labelFunction: () => i18next.t('登録済み'),
  },
  [ImportProcessStatus.Existing]: {
    order: 10,
    value: ImportProcessStatus.Existing,
    labelFunction: () => i18next.t('既に登録済み'),
  },
  [ImportProcessStatus.Removed]: {
    order: 10,
    value: ImportProcessStatus.Removed,
    labelFunction: () => i18next.t('削除'),
  },
});

export const ExportedFileTypesOptions = (dict: UseDictionnaryReturn) =>
  new EnumMap({
    [ExportedFileTypes.ExpUsers]: {
      order: 10,
      value: ExportedFileTypes.ExpUsers,
      labelFunction: () => i18next.t('メンバー'),
    },
    [ExportedFileTypes.ExpTeams]: {
      order: 20,
      value: ExportedFileTypes.ExpTeams,
      labelFunction: () => i18next.t('{{wTeam}}', dict),
    },
    [ExportedFileTypes.ExpReportLines]: {
      order: 30,
      value: ExportedFileTypes.ExpReportLines,
      labelFunction: () => i18next.t('レポートライン'),
    },
    [ExportedFileTypes.ExpO3Pairs]: {
      order: 40,
      value: ExportedFileTypes.ExpO3Pairs,
      labelFunction: () => i18next.t('1on1のペア設定'),
    },
    [ExportedFileTypes.ExpObjectives]: {
      order: 50,
      value: ExportedFileTypes.ExpObjectives,
      labelFunction: () => i18next.t('目標'),
    },
    [ExportedFileTypes.ExpFeedbackSheets]: {
      order: 60,
      value: ExportedFileTypes.ExpFeedbackSheets,
      labelFunction: () => i18next.t('フィードバックシート'),
    },
    [ExportedFileTypes.ExpObjectiveSheets]: {
      order: 70,
      value: ExportedFileTypes.ExpObjectiveSheets,
      labelFunction: () => i18next.t('{{wObjectiveSheet}}', dict),
    },
  });

export const ExtO3MeetingStatusesOptions = new EnumMap({
  [ExtO3MeetingStatuses.Active]: {
    order: 10,
    value: ExtO3MeetingStatuses.Active,
    labelFunction: () => i18next.t('アクティブ'),
  },
  [ExtO3MeetingStatuses.Canceled]: {
    order: 20,
    value: ExtO3MeetingStatuses.Canceled,
    labelFunction: () => i18next.t('キャンセル'),
  },
  [ExtO3MeetingStatuses.Completed]: {
    order: 30,
    value: ExtO3MeetingStatuses.Completed,
    labelFunction: () => i18next.t('完了'),
  },
  [ExtO3MeetingStatuses.Deleted]: {
    order: 40,
    value: ExtO3MeetingStatuses.Deleted,
    labelFunction: () => i18next.t('削除'),
  },
});

export const ExtO3TicketStatusesOptions = new EnumMap({
  [ExtO3TicketStatuses.Active]: {
    order: 10,
    value: ExtO3TicketStatuses.Active,
    labelFunction: () => i18next.t('アクティブ'),
  },
  [ExtO3TicketStatuses.Expired]: {
    order: 20,
    value: ExtO3TicketStatuses.Expired,
    labelFunction: () => i18next.t('期限切れ'),
  },
  [ExtO3TicketStatuses.Used]: {
    order: 30,
    value: ExtO3TicketStatuses.Used,
    labelFunction: () => i18next.t('使用済み'),
  },
  [ExtO3TicketStatuses.Deleted]: {
    order: 40,
    value: ExtO3TicketStatuses.Deleted,
    labelFunction: () => i18next.t('削除'),
  },
});

export const ExtO3ProjectTypesOptions = new EnumMap({
  [ExtO3ProjectTypes.Subscription]: {
    order: 10,
    value: ExtO3ProjectTypes.Subscription,
    label: i18next.t('Subscription'),
  },
  [ExtO3ProjectTypes.Ticket]: {
    order: 20,
    value: ExtO3ProjectTypes.Ticket,
    label: i18next.t('Ticket'),
  },
});

export const ExtO3PairStatusOptions = new EnumMap({
  [ExtO3PairStatuses.Active]: {
    order: 10,
    value: ExtO3PairStatuses.Active,
    labelFunction: () => i18next.t('有効'),
  },
  [ExtO3PairStatuses.Suspended]: {
    order: 20,
    value: ExtO3PairStatuses.Suspended,
    labelFunction: () => i18next.t('停止中'),
  },
  [ExtO3PairStatuses.Deleted]: {
    order: 20,
    value: ExtO3PairStatuses.Deleted,
    labelFunction: () => i18next.t('削除'),
  },
});

export const ExtO3ProjectMentorStatusOptions = new EnumMap({
  [ExtO3ProjectMentorStatuses.Active]: {
    order: 10,
    value: ExtO3ProjectMentorStatuses.Active,
    labelFunction: () => i18next.t('有効'),
  },
  [ExtO3ProjectMentorStatuses.Deleted]: {
    order: 20,
    value: ExtO3ProjectMentorStatuses.Deleted,
    labelFunction: () => i18next.t('削除'),
  },
});

export const ExtO3ProjectMenteeStatusOptions = new EnumMap({
  [ExtO3ProjectMenteeStatuses.Active]: {
    order: 10,
    value: ExtO3ProjectMenteeStatuses.Active,
    labelFunction: () => i18next.t('有効'),
  },
  [ExtO3ProjectMenteeStatuses.Deleted]: {
    order: 20,
    value: ExtO3ProjectMenteeStatuses.Deleted,
    labelFunction: () => i18next.t('削除'),
  },
});

export const OrderByEnumOptions = new EnumMap({
  desc: {
    order: 10,
    value: OrderByEnum.Desc,
    labelFunction: () => i18next.t('新しい順'),
  },
  asc: {
    order: 20,
    value: OrderByEnum.Asc,
    labelFunction: () => i18next.t('古い順'),
  },
});
