import { useEffect, MutableRefObject } from 'react';

function useClickOutside(refs: MutableRefObject<any>[], callback: (event?: MouseEvent) => void) {
  function handleClickOutside(event: MouseEvent) {
    if (refs.length > 0 && refs.every(ref => !ref?.current?.contains?.(event.target as Node))) {
      callback(event);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

export default useClickOutside;
