import { FC } from 'react';

import { CommonIconProps, defaultPathClassName } from './utils';
const CloseIcon: FC<CommonIconProps> = props => {
  const { size = '100%', className, pathClassName = defaultPathClassName } = props;
  return (
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        className={pathClassName}
        d="M12.8837 12L19.4725 5.41124C19.7162 5.16749 19.7162 4.77124 19.4725 4.52749C19.2287 4.28374 18.8325 4.28374 18.5887 4.52749L12 11.1162L5.41124 4.52749C5.16749 4.28374 4.77124 4.28374 4.52749 4.52749C4.28374 4.77124 4.28374 5.16749 4.52749 5.41124L11.1162 12L4.52749 18.5887C4.28374 18.8325 4.28374 19.2287 4.52749 19.4725C4.64999 19.595 4.80999 19.655 4.96999 19.655C5.12999 19.655 5.28999 19.5937 5.41249 19.4725L12 12.8837L18.5887 19.4725C18.7112 19.595 18.8712 19.655 19.0312 19.655C19.1912 19.655 19.3512 19.5937 19.4737 19.4725C19.7175 19.2287 19.7175 18.8325 19.4737 18.5887L12.8837 12Z"
      />
    </svg>
  );
};

export default CloseIcon;
