import { FC, ReactNode } from 'react';

import { TextAlign } from '../../quarks/Typo';
import { twMerge } from 'tailwind-merge';

interface SideSheetFooterProps {
  sticky?: boolean;
  className?: string;
  align?: TextAlign;
  size?: string;
  children?: ReactNode;
}

const SideSheetFooter: FC<SideSheetFooterProps> = ({ children, className, sticky, size }) => (
  <div
    className={twMerge([
      'p-5',
      'border-t border-solid border-border-01 dark:border-border-01-dark',
      sticky && 'justify-self-end bg-ui-01 dark:bg-ui-01-dark w-full z-[100]',
      size === 'tiny' && 'pt-5 pr-4 pb-3 pl-2 md:p-5',
      className,
    ])}
  >
    {children}
  </div>
);

export default SideSheetFooter;
