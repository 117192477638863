import { ReactNode, useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { twJoin, twMerge } from 'tailwind-merge';

export const TabAlertIcon = () => (
  <div className="bg-error h-[8px] w-[8px] shrink-0 rounded-full" />
);

export interface TabItemType<K = string> {
  key: K;
  label: string | ReactNode;
  isLink?: boolean;
  disabled?: boolean;
  hide?: boolean;
  alertIcon?: boolean;
  icon?: ReactNode;
  href?: string;
}

export type TabSize = 'default' | 'large';
export type TabVariant = 'default' | 'buttonTabs';

interface TabsProps<K = string> {
  items: TabItemType<K>[];
  current: K;
  onClick?: (key: K) => void;
  fluid?: boolean;
  size?: TabSize;
  direction?: 'horizontal' | 'vertical';
  controlled?: boolean;
  border?: boolean;
  fluidOnMobile?: boolean;
  className?: string;
  ulClassName?: string;
  buttonClassName?: string;
  svgClassName?: string;
  variant?: TabVariant;
  highlightCurrent?: boolean;
  hideIconOnMobile?: boolean;
}

const gridColMap = [
  'grid-cols-0',
  'grid-cols-1',
  'grid-cols-2',
  'grid-cols-3',
  'grid-cols-4',
  'grid-cols-5',
  'grid-cols-6',
  'grid-cols-7',
  'grid-cols-8',
  'grid-cols-9',
  'grid-cols-10',
  'grid-cols-11',
  'grid-cols-12',
];

const Tabs: <K = string>(props: TabsProps<K>) => JSX.Element | null = ({
  items,
  current,
  onClick,
  fluid = false,
  fluidOnMobile = false,
  size = 'default',
  direction = 'horizontal',
  controlled = false,
  border = true,
  className,
  ulClassName,
  buttonClassName,
  svgClassName = '[&_svg]:!w-[16px] [&_svg]:!h-[16px]',
  variant = 'default',
  highlightCurrent = false,
  hideIconOnMobile = false,
}) => {
  const initTab = items?.find(i => i.key === current);
  const [currentTab, setCurrentTab] = useState(initTab);

  useEffect(() => {
    const tab = items?.find(i => i.key === current);
    if (tab && tab.key !== currentTab?.key) {
      setCurrentTab(tab);
    }
  }, [current, items, currentTab, setCurrentTab]);

  const onTabClick = useCallback(
    item => {
      if (item?.disabled) {
        return;
      }

      if (currentTab && currentTab.key !== item.key) {
        if (onClick) {
          onClick(item.key);
        }
      }
      if (!controlled) {
        setCurrentTab(item);
      }
    },
    [currentTab, onClick, setCurrentTab],
  );

  const displayItems = items?.filter(i => !i.hide);

  const refs = useRef<(HTMLLIElement | null)[]>([]);
  useEffect(() => {
    refs.current = refs.current.slice(0, items.length);
  }, [items.length]);

  useEffect(() => {
    if (currentTab) {
      const index = displayItems.findIndex(i => i.key === currentTab.key);
      if (refs.current[index]) {
        refs.current[index].scrollIntoView({ behavior: 'instant', block: 'nearest' });
      }
    }
  }, []);

  const cnTabsWrap = [
    'w-full h-fit',
    '[&_::-webkit-scrollbar]:h-[4px] [&_::-webkit-scrollbar]:md:h-[6px] [&_::-webkit-scrollbar-thumb]:bg-ui-02 [&_::-webkit-scrollbar-thumb]:dark:bg-ui-02-dark [&_::-webkit-scrollbar-thumb]:rounded-full',
  ];

  if (border && direction === 'horizontal') {
    cnTabsWrap.push('border-b-ui-02 dark:border-b-ui-02-dark border-solid border-b');
  }

  const cnTabsList = ['flex items-center overflow-x-auto'];
  const cnTabsListItem = ['w-fit flex flex-col items-center justify-center'];

  const cnTabsListItemButton = [
    'flex flex-col flex-grow justify-center items-center',
    'group relative w-full h-full',
    'text-sm text-base-02 dark:text-base-02-dark text-center capitalize-first text-ellipsis',
    'bg-transparent outline-none cursor-pointer',
    '[&_svg]:shrink-0',
    (variant === 'default' || direction === 'vertical') && svgClassName,
    hideIconOnMobile && '[&_svg]:hidden md:[&_svg]:block',
  ];
  const cbTabsListItemButtonWrap: string[] = [
    'flex flex-row flex-wrap items-center justify-center gap-y-1',
    variant === 'buttonTabs' ? 'gap-x-4' : 'gap-x-3',
  ];

  if (direction === 'horizontal') {
    if (fluid) {
      cnTabsList.push('w-full overflow-x-hidden');
      cnTabsList.push('grid', gridColMap[displayItems.length]);
      cnTabsListItem.push('w-full');
    } else {
      if (!fluidOnMobile) {
        cbTabsListItemButtonWrap.push('whitespace-nowrap');
      }
    }

    if (fluidOnMobile) {
      cnTabsList.push(
        'overflow-x-hidden sm:overflow-x-auto grid',
        gridColMap[displayItems.length],
        'sm:flex',
      );
      cnTabsListItem.push('w-full sm:w-fit');
      cbTabsListItemButtonWrap.push('whitespace-normal md:whitespace-nowrap');
    }

    cnTabsListItem.push('self-stretch');
    switch (size) {
      case 'default':
      default:
        cnTabsListItem.push('min-h-[40px]');
        break;
      case 'large':
        cnTabsListItem.push('min-h-[56px]');
        break;
    }

    cnTabsListItemButton.push(
      twMerge([
        'px-5 py-0 sm:py-1',
        'border-transparent border-solid',
        variant === 'default' && 'border-b-[2px]',
      ]),
    );
  } else if (direction === 'vertical') {
    cnTabsList.push('flex-col items-start');
    cnTabsListItem.push('min-h-[56px] h-auto border-b-transparent w-full');
    cnTabsListItemButton.push('px-4 !border-l-transparent border-l-solid border-l-[2px]');
    if (variant === 'buttonTabs') {
      cnTabsListItemButton.push('!border-r-0');
    }
  }

  if (variant === 'buttonTabs') {
    cnTabsWrap.push(
      'bg-ui-01 dark:bg-ui-01-dark border-solid border-ui-02 dark:border-ui-02-dark border-[1px] rounded-[8px] overflow-clip',
    );
    cnTabsList.push('min-h-[42px]');
    cnTabsListItem.push('[&_button]:last:border-r-[0px]');
    cnTabsListItemButton.push(
      'border-solid border-r-[1px] border-r-[1px] border-ui-02 dark:border-ui-02-dark',
    );
    cbTabsListItemButtonWrap.push('flex-nowrap');
  }

  if (!displayItems.length) {
    return null;
  }

  return (
    <div className={twMerge(cnTabsWrap, className)}>
      <ul className={twMerge(cnTabsList, ulClassName)}>
        {displayItems.map((item, index) => {
          const _current = currentTab && currentTab.key === item.key;
          const cnTabsListItemMapped = [
            item.disabled && 'pointer-events-none',
            item.disabled && !_current && 'opacity-50',
          ];
          const cnTabsListItemButtonMapped = [];

          if (_current) {
            cnTabsListItemButtonMapped.push(
              'pointer-events-none text-base-01 dark:text-base-01-dark',
            );
            highlightCurrent &&
              cnTabsListItemButtonMapped.push('bg-quinary text-white [&_path]:!fill-white');
            direction === 'horizontal' &&
              variant === 'default' &&
              cnTabsListItemButtonMapped.push('border-solid border-b-[2px] border-primary');
            direction === 'vertical' &&
              cnTabsListItemButtonMapped.push('border-solid border-l-[2px] !border-l-primary');
          }

          const ButtonBody = (
            <div className={twJoin(cbTabsListItemButtonWrap)}>
              {item.icon && <>{item.icon}</>}
              <span
                className={twMerge([
                  'leading-none capitalize-first',
                  'group-hover:text-base-02 group-hover:dark:text-base-02-dark',
                  _current && 'font-bold',
                ])}
              >
                {item.label}
              </span>
              {item.alertIcon && <TabAlertIcon />}
            </div>
          );

          return (
            <li
              className={twMerge(cnTabsListItem, cnTabsListItemMapped)}
              key={index}
              ref={el => (refs.current[index] = el)}
            >
              {item.isLink && item.href ? (
                <>
                  {/* @ts-expect-error */}
                  <Link
                    to={item.href}
                    className={twMerge(
                      cnTabsListItemButton,
                      cnTabsListItemButtonMapped,
                      buttonClassName,
                    )}
                  >
                    {ButtonBody}
                  </Link>
                </>
              ) : (
                <button
                  className={twMerge(
                    cnTabsListItemButton,
                    cnTabsListItemButtonMapped,
                    buttonClassName,
                  )}
                  onClick={() => onTabClick(item)}
                >
                  {ButtonBody}
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;
