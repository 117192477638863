import { FC, memo, ReactNode } from 'react';
import Flex from '../../quarks/Flex';
import { twJoin } from 'tailwind-merge';

export type AlertVariant =
  | 'empty'
  | 'success'
  | 'error'
  | 'light'
  | 'warning'
  | 'info'
  | 'infoPale';

const alertVariantMap: Record<AlertVariant, string> = {
  empty: 'bg-ui-01 dark:bg-ui-01-dark text-base-01 dark:text-base-01-dark',
  success: 'bg-quaternary text-base-05 dark:text-base-05-dark',
  error: 'bg-quinary text-base-05 dark:text-base-05-dark',
  light:
    'bg-ui-01 dark:bg-ui-01-dark text-base-01 dark:text-base-01-dark border border-solid border-border-01 dark:border-border-01-dark',
  warning: 'bg-secondary text-base-05 dark:text-base-05-dark',
  info: 'bg-quaternary text-base-05 dark:text-base-05-dark',
  infoPale: 'bg-quaternary/30 dark:bg-gray-400 text-quaternary dark:text-base-05-dark',
};

interface AlertProps {
  variant?: AlertVariant;
  icon?: FC;
  rightNode?: ReactNode;
  children?: ReactNode;
  iconPathClassName?: string;
}

const Alert: FC<AlertProps> = memo(
  ({ variant, icon, rightNode, children, iconPathClassName = 'fill-current' }) => {
    const IconComponent: FC<{
      className?: string;
      pathClassName?: string;
    }> | null = icon ? icon : null;

    const classNames = [
      'rounded-xs w-full min-h-[56px] p-4 max-w-full text-base flex grow items-center',
    ];
    if (variant) {
      classNames.push(alertVariantMap[variant]);
    }

    return (
      <div className="bg-ui-01 rounded-xs">
        <div className={twJoin(classNames)}>
          <Flex grow spaced alignItems="center">
            <Flex alignItems="center">
              {IconComponent && (
                <div className="min-w-[28px] w-[28px] min-h-[28px] h-[28px]">
                  <IconComponent className="w-full" pathClassName={iconPathClassName} />
                </div>
              )}
              <div>{children}</div>
            </Flex>
            {!!rightNode && <div className="shrink-0">{rightNode}</div>}
          </Flex>
        </div>
      </div>
    );
  },
);

export default Alert;
