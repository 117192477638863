export const size = {
  min: 320,
  xs: 375,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  mx: 1440,
};

export const maxWidth = {
  min: `(max-width: ${size.min}px)`,
  xs: `(max-width: ${size.xs}px)`,
  sm: `(max-width: ${size.sm}px)`,
  md: `(max-width: ${size.md}px)`,
  lg: `(max-width: ${size.lg}px)`,
  xl: `(max-width: ${size.xl}px)`,
  mx: `(max-width: ${size.mx}px)`,
  mobile: `(max-width: ${size.md}px)`,
  tablet: `(max-width: ${size.lg}px)`,
  laptop: `(max-width: ${size.xl}px)`,
};

export const minWidth = {
  xs: `(min-width: ${size.min}px)`,
  sm: `(min-width: ${size.xs + 1}px)`,
  md: `(min-width: ${size.sm + 1}px)`,
  lg: `(min-width: ${size.md + 1}px)`,
  xl: `(min-width: ${size.lg + 1}px)`,
  mx: `(min-width: ${size.xl + 1}px)`,
  mobile: `(min-width: ${size.min}px)`,
  tablet: `(min-width: ${size.md + 1}px)`,
  laptop: `(min-width: ${size.lg + 1}px)`,
};
