import { FC, ReactNode, useState, useRef, createContext } from 'react';
import { Manager, Reference, Popper as ReactPopper } from 'react-popper';
import ReactDOM from 'react-dom';

import { BasePopper, PopperTarget, PopperWrap, PopperArrow } from './styled';
import useClickOutside from '../../../lib/use-clickoutside';
import usePortal from '../../../lib/use-portal';

export type PopperPlacementTypes =
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';

interface BasePopperProps {
  context?: string;
  trigger?: ReactNode;
  triggerOnOpen?: ReactNode;
  placement?: PopperPlacementTypes;
  arrow?: boolean;
  children: ReactNode;
  eventsEnabled?: boolean;
  popperChildrenVariant?: string;
  displayPopperTarget?: string;
  keepOpen?: boolean;
  zIndex?: number;
  triggerClassName?: string;
  portalId?: string;
  withCreatePortal?: boolean;
}

interface PopperContextProps {
  hidePopper: () => void;
  showPopper: () => void;
  isOpen: boolean;
  children?: ReactNode;
}

export const PopperContext = createContext<PopperContextProps>({} as PopperContextProps);

const Popper: FC<BasePopperProps> = ({
  children,
  trigger,
  triggerOnOpen,
  placement,
  arrow,
  eventsEnabled,
  popperChildrenVariant,
  displayPopperTarget,
  keepOpen = false,
  zIndex,
  context,
  triggerClassName,
  portalId,
  withCreatePortal = true,
}) => {
  const [show, setShow] = useState(keepOpen ? true : false);
  const popperRef = useRef();

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (keepOpen && show) {
      return;
    }
    setShow(!show);
  };

  useClickOutside([popperRef], () => {
    if (keepOpen) return;

    if (show) {
      setShow(!show);
    }
  });

  const providerValue = {
    hidePopper: () => setShow(false),
    showPopper: () => setShow(true),
    isOpen: show,
  };

  const { target } = usePortal(portalId || 'popper');

  return (
    <Manager>
      <PopperContext.Provider value={providerValue}>
        <PopperTarget display={displayPopperTarget} className={triggerClassName}>
          <Reference>
            {({ ref }) => (
              <>
                <span
                  className="block"
                  onClick={e => e.stopPropagation()}
                  onMouseDown={(e: any) => handleClick(e)}
                  ref={ref}
                >
                  {show ? (triggerOnOpen ? triggerOnOpen : trigger) : trigger}
                </span>

                {show && (
                  <ReactPopper
                    eventsEnabled={eventsEnabled}
                    innerRef={popperRef}
                    placement={placement}
                    modifiers={{
                      flip: { behavior: 'flip' },
                      preventOverflow: { boundariesElement: 'window' },
                    }}
                  >
                    {({ ref, style, placement: dataPlacement, arrowProps }) =>
                      withCreatePortal ? (
                        ReactDOM.createPortal(
                          <PopperWrap
                            ref={ref}
                            style={style}
                            data-placement={dataPlacement}
                            zIndex={zIndex}
                            context={context}
                          >
                            <BasePopper variant={popperChildrenVariant}>{children}</BasePopper>

                            {arrow && (
                              <PopperArrow
                                placement={dataPlacement}
                                ref={arrowProps.ref}
                                style={arrowProps.style}
                              />
                            )}
                          </PopperWrap>,
                          target,
                        )
                      ) : (
                        <>
                          <PopperWrap
                            ref={ref}
                            style={style}
                            data-placement={dataPlacement}
                            zIndex={zIndex}
                            context={context}
                          >
                            <BasePopper variant={popperChildrenVariant}>{children}</BasePopper>
                          </PopperWrap>
                          {arrow && (
                            <PopperArrow
                              placement={dataPlacement}
                              ref={arrowProps.ref}
                              style={arrowProps.style}
                            />
                          )}
                        </>
                      )
                    }
                  </ReactPopper>
                )}
              </>
            )}
          </Reference>
        </PopperTarget>
      </PopperContext.Provider>
    </Manager>
  );
};

export default Popper;
