import styled, { css } from 'styled-components';

interface BaseGearMenuItemProps {
  disabled?: boolean;
  warn?: boolean;
}
export const BaseGearMenuItem = styled.li`
  padding: var(--spacing-4);
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.9rem;
  text-align: left;
  color: var(--text-01);
  position: relative;
  white-space: nowrap;
  ::first-letter {
    text-transform: capitalize;
  }

  svg {
    vertical-align: middle;
    display: inline-block;
    margin-right: var(--spacing-3);
    width: 20px;
    height: 20px;
    position: absolute;
    left: 9px;
    top: calc(50% - 10px);
    path {
      fill: var(--text-01)!important;
    }

    + p {
      margin-left: 24px;
    }
  }
  p {
    min-width: 64px;
  }

  ${(props: BaseGearMenuItemProps) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: var(--text-disabled);
      svg {
        path {
          fill: var(--text-disabled)!important;
        }
      }
    `}

  ${(props: BaseGearMenuItemProps) =>
    !props.disabled &&
    css`
      :hover {
        background: var(--interactive-01);
        color: var(--text-05);

        svg {
          path {
            fill: var(--text-05)!important;
          }
        }
      }
    `};

  ${(props: BaseGearMenuItemProps) =>
    props.warn &&
    css`
      color: var(--text-error);
      svg {
        path {
          fill: var(--text-error)!important;
        }
      }

      :hover {
        background: var(--error);
        color: var(--text-05);

        svg {
          path {
            fill: var(--text-05)!important;
          }
        }
      }
    `};
`;

export const BaseGearMenuList = styled.ul``;
