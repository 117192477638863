import { FC, ReactNode, Ref } from 'react';
import { twMerge } from 'tailwind-merge';

interface SideSheetBodyProps {
  className?: string;
  variant?: string;
  innerRef?: Ref<any>;
  children?: ReactNode;
}

const SideSheetBody: FC<SideSheetBodyProps> = ({ children, className, variant, innerRef }) => (
  <div
    className={twMerge([
      'py-7 px-5 md:py-8 md:px-7 overflow-auto overflow-x-hidden flex-grow',
      variant === 'gray' && 'bg-ui-background-01 dark:bg-ui-background-01-dark',
      className,
    ])}
    ref={innerRef}
  >
    {children}
  </div>
);

export default SideSheetBody;
