import { ReactNode, FC } from 'react';
import Tabs, { TabItemType } from '../../atoms/Tabs';
import Flex from '../../quarks/Flex';
import Typo from '../../quarks/Typo';
import Responsive from '../../atoms/Responsive';

interface SheetActionTabsProps<K = string> {
  current: K;
  items: TabItemType<K>[];
  onClick?: (key: K) => void;
}

const SheetActionTabs: <K = string>(props: SheetActionTabsProps<K>) => JSX.Element | null = ({
  items = [],
  current,
  onClick,
}) => {
  if (!items.length) {
    return null;
  }
  const laptopItems = items.map(item => ({
    ...item,
    hide: item.key === 'main' ? true : item.hide || false,
  }));

  return (
    <>
      <Responsive mobile flex className="flex-grow">
        <Tabs controlled current={current} items={items} fluid size="large" onClick={onClick} />
      </Responsive>
      <Responsive tablet laptop>
        <div className="w-full">
          <Tabs
            controlled
            current={current}
            items={laptopItems}
            direction="vertical"
            onClick={onClick}
            buttonClassName="px-0"
          />
        </div>
      </Responsive>
    </>
  );
};

interface SheetActionTabsButtonProps {
  text: string;
  icon: ReactNode;
}

export const SheetActionTabsButton: FC<SheetActionTabsButtonProps> = ({ text, icon }) => {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      between="none"
      className=""
    >
      {icon}
      <Typo
        variant="label-03"
        className="tracking-normal md:tracking-tighter text-center"
        break="all"
        overflow="ellipsis"
        color="text-02"
      >
        {text}
      </Typo>
    </Flex>
  );
};

export default SheetActionTabs;
