import { FC, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const createRootElement = (id: string) => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
};

const addRootElement = (rootElement: Element) => {
  document.body.insertBefore(rootElement, document!.body!.lastElementChild!.nextElementSibling);
};

interface PortalProps {
  id: string;
}

const Portal: FC<PortalProps> = ({ children, id }) => {
  const { element } = usePortal(id);
  return ReactDOM.createPortal(children, element);
};

const usePortal = (id: string) => {
  const rootElementRef = useRef<Element | null>(null);
  const existingParent = document.querySelector(`#${id}`);
  const parentElement = existingParent || createRootElement(id);

  useEffect(() => {
    if (!existingParent) {
      addRootElement(parentElement);
    }

    parentElement.appendChild(rootElementRef.current as Element);

    return () => {
      if (!rootElementRef || !rootElementRef.current) return;
      // For IE11
      if (!(rootElementRef.current as Element).remove) {
        rootElementRef.current?.parentNode?.removeChild(rootElementRef.current);
        if (parentElement?.childNodes?.length === -1) {
          parentElement.parentNode?.removeChild(parentElement);
        }
      } else {
        (rootElementRef.current as Element).remove();
        if (parentElement?.childNodes?.length === -1) {
          parentElement.remove();
        }
      }
    };
  }, [existingParent, parentElement]);

  const getRootElement = () => {
    if (!rootElementRef.current) {
      rootElementRef.current = document.createElement('div');
    }
    return rootElementRef.current;
  };

  const target = getRootElement();

  return {
    target,
    element: parentElement,
  };
};

export { usePortal as default, Portal };
