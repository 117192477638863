import React, { FC, memo, ReactNode } from 'react';

import { BaseGearMenuList } from './styled';

interface GearMenuListProps {
  children?: ReactNode;
}
const GearMenuList: FC<GearMenuListProps> = memo(({ children }) => {
  return <BaseGearMenuList>{children}</BaseGearMenuList>;
});

export default GearMenuList;
