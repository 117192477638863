import { FC, memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 're-design';
import Popper, { PopperPlacementTypes } from '../Popper';
import EllipsisIcon from '../../quarks/icons/Ellipsis';

interface GearMenuProps {
  context?: string;
  placement?: PopperPlacementTypes;
  trigger?: ReactNode;
  triggerOnOpen?: ReactNode;
  arrow?: boolean;
  buttonSize?: 'tiny' | 'small' | 'normal' | 'large';
  className?: string;
  zIndex?: number;
  children?: ReactNode;
}

const GearMenu: FC<GearMenuProps> = memo(
  ({
    children,
    placement = 'left',
    trigger,
    triggerOnOpen,
    arrow = true,
    buttonSize = 'normal',
    context,
    className,
    zIndex = 1200,
  }) => {
    const { t } = useTranslation();

    return (
      <Popper
        portalId="gearMenu"
        context={context}
        triggerClassName={className}
        zIndex={zIndex + 1}
        trigger={
          trigger || (
            <Button
              type="button"
              icon={<EllipsisIcon />}
              size={buttonSize}
              onlyIcon
              variant="transparent"
            >
              {t('編集')}
            </Button>
          )
        }
        triggerOnOpen={triggerOnOpen}
        placement={placement}
        arrow={arrow}
      >
        {children}
      </Popper>
    );
  },
);
export default GearMenu;
