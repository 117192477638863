import styled from 'styled-components';

export const BaseSideSheetHeaderInnerActionsItem = styled.div`
  position: relative;

  & + & {
    margin-left: 8px;
    :before {
      content: '';
      height: 24px;
      top: 50%;
      left: -4px;
      transform: translateY(-50%);
      background: var(--border-01);
      width: 1px;
      position: absolute;
    }
  }
`;
