import { FC, ReactNode } from 'react';
import { Button } from 're-design';
import { useTranslation } from 'react-i18next';
import { BaseSideSheetHeaderInnerActionsItem } from './styled';
import { hasElement } from '../../../utils/array';
import { useCloseSideSheet } from '../../../lib/sidesheet';
import Heading from '../../quarks/Heading';
import Breadcrumb, { BreadcrumbLabel } from '../../quarks/Breadcrumb';
import CloseIcon from '../../quarks/icons/Close';
import AngleArrowLeftIcon from '../../quarks/icons/AngleArrowLeft';
import { twMerge } from 'tailwind-merge';

interface SideSheetHeaderProps {
  title: ReactNode;
  icon?: ReactNode;
  actions?: ReactNode;
  sticky?: boolean;
  onBack?(): any;
  hasPreviousSideSheets?: boolean;
  meta?: ReactNode;
  breadcrumbLabels?: BreadcrumbLabel[];
  onClose?: () => void;
}

export const SideSheetHeader: FC<SideSheetHeaderProps> = ({
  title,
  icon,
  actions,
  sticky,
  meta,
  breadcrumbLabels = [],
  onClose,
  onBack,
}) => {
  const { t } = useTranslation();
  const closeSideSheet = useCloseSideSheet();

  return (
    <div
      className={twMerge([
        'p-5 h-[72px] min-h-[72px] z-[2]',
        'border-b border-solid border-border-01 dark:border-border-01-dark',
        '[&_nav]:!mb-3',
        sticky && 'sticky top-0 bg-ui-01 dark:bg-ui-01-dark',
      ])}
    >
      <div className="flex items-center h-[40px]">
        {!!onBack && (
          <div className="-ml-4">
            <Button
              icon={<AngleArrowLeftIcon />}
              onlyIcon
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                if (onBack) {
                  onBack();
                }
              }}
            >
              {t('戻る')}
            </Button>
          </div>
        )}

        {icon ? <div className="flex mr-4 items-center shrink-0">{icon}</div> : null}

        <div className="flex flex-col flex-grow overflow-hidden">
          {hasElement(breadcrumbLabels) && <Breadcrumb labels={breadcrumbLabels} />}
          <Heading
            variant="h3"
            className="leading-none text-ellipsis whitespace-nowrap overflow-hidden"
          >
            {title}
          </Heading>
          {meta && <div className="mt-3">{meta}</div>}
        </div>

        <div className="flex items-center mr-4 -mr-2">
          {actions && (
            <BaseSideSheetHeaderInnerActionsItem>{actions}</BaseSideSheetHeaderInnerActionsItem>
          )}
          <BaseSideSheetHeaderInnerActionsItem>
            <Button
              variant="transparent"
              icon={<CloseIcon />}
              onlyIcon
              onClick={onClose || closeSideSheet}
            >
              {t('閉じる')}
            </Button>
          </BaseSideSheetHeaderInnerActionsItem>
        </div>
      </div>
    </div>
  );
};
export default SideSheetHeader;
