import i18next from 'i18next';
import format from 'date-fns/format';
import addYears from 'date-fns/addYears';
import startOfDay from 'date-fns/startOfDay';
import subMonths from 'date-fns/subMonths';
import setMonth from 'date-fns/setMonth';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import getDay from 'date-fns/getDay';
import getUnixTime from 'date-fns/getUnixTime';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInYears from 'date-fns/differenceInYears';
import subWeeks from 'date-fns/subWeeks';
import subDays from 'date-fns/subDays';
import monthsToQuarters from 'date-fns/monthsToQuarters';
import isDate from 'date-fns/isDate';

import { CycleWeekThresholds } from '../generated/graphql';
import ja from 'date-fns/locale/ja';
import enUS from 'date-fns/locale/en-US';
import { getCurrentLangFromLocaleStorage } from '../lib/use-language';

const langLocale: any = {
  en: enUS,
  ja,
};

export const _format = (date: Date | number, _format: string) => {
  const currentLang = getCurrentLangFromLocaleStorage();
  return format(date, _format, { locale: langLocale[currentLang] });
};

export const yyyyMMddHHmm = (date: Date | number) => {
  return _format(date, 'yyyy/MM/dd HH:mm');
};

export const yyyyMMJp = (date: Date | number) => {
  return _format(date, 'yyyy年MM月');
};

export const DDD = (date: Date) => {
  return _format(date, 'EEEE');
};

//Mon.Tue,Wed...
export const ddd = (date: Date) => {
  return _format(date, 'EEE');
};

export const MMMdoEEE = (date: Date) => {
  return _format(date, 'MMMdo（EEE）');
};

export const MMddEEE = (date: Date) => {
  return _format(date, 'MM/dd（EEE）');
};

export const yyyyMM = (date: Date) => {
  return _format(date, 'yyyy/MM');
};

export const MMdd = (date: Date) => {
  return _format(date, 'MM/dd');
};

export const LLLL = (date: Date) => {
  return _format(date, 'LLLL');
};

export const yyyyMMdd = (date: Date | number) => {
  return _format(date, 'yyyy/MM/dd');
};

export const yyyyMMddKey = (date: Date) => {
  return _format(date, 'yyyy-MM-dd');
};

export const yyyyMMddEEE = (date: Date) => {
  return _format(date, 'yyyy/MM/dd（EEE）');
};

export const yyyyMMddEEEHHmm = (date: Date | number) => {
  return _format(date, 'yyyy/MM/dd（EEE） HH:mm');
};

export const yyyyMMddEEEHHmmaaaa = (date: Date | number) => {
  return _format(date, 'yyyy/MM/dd（EEE） HH:mm aaaa');
};

export const monthOptions: SelectOption[] = [...Array(12)].map((_, i) => {
  const dateObj = new Date();
  dateObj.setMonth(i);

  return {
    value: i + 1,
    label: LLLL(dateObj),
    order: i * 10,
  };
});

export const getDiff = (date: Date, now: Date = new Date()): string | null => {
  if (!date) return null;

  const diffSeparate = getDiffSeparate(date, now);
  if (!diffSeparate) {
    return null;
  }

  const { fullText } = diffSeparate;
  return fullText;
};

export const getDiffSeparate = (
  date: Date,
  now: Date = new Date(),
): {
  number?: number;
  text: string;
  fullText: string;
  fullTextTrans: string;
} | null => {
  if (!date) return null;
  if (isAfter(date, now)) {
    return {
      text: i18next.t('たった今'),
      fullText: i18next.t('たった今'),
      fullTextTrans: 'たった今',
    };
  }
  const years = differenceInYears(startOfDay(now), startOfDay(date));
  if (years >= 1) {
    return {
      number: years,
      text: i18next.t('年前'),
      fullText: i18next.t('{{count}}年前', {
        count: years,
      }),
      fullTextTrans: '{{count}}<0>年前<0>',
    };
  }
  const months = differenceInMonths(startOfDay(now), startOfDay(date));
  if (months >= 1) {
    return {
      number: months,
      text: i18next.t('ヶ月前'),
      fullText: i18next.t('{{count}}ヶ月前', {
        count: months,
      }),
      fullTextTrans: '{{count}}<0>ヶ月前<0>',
    };
  }
  const weeks = differenceInWeeks(startOfDay(now), startOfDay(date));
  if (weeks >= 1) {
    return {
      number: weeks,
      text: i18next.t('週間前'),
      fullText: i18next.t('{{count}}週間前', {
        count: weeks,
      }),
      fullTextTrans: '{{count}}<0>週間前<0>',
    };
  }
  const days = differenceInDays(startOfDay(now), startOfDay(date));
  if (days >= 1) {
    return {
      number: days,
      text: i18next.t('日前'),
      fullText: i18next.t('{{count}}日前', {
        count: days,
      }),
      fullTextTrans: '{{count}}<0>日前<0>',
    };
  }
  const hours = differenceInHours(now, date);
  if (hours >= 1) {
    return {
      number: hours,
      text: i18next.t('時間前'),
      fullText: i18next.t('{{count}}時間前', {
        count: hours,
      }),
      fullTextTrans: '{{count}}<0>時間前<0>',
    };
  }
  const minutes = differenceInMinutes(now, date);
  if (minutes >= 1) {
    return {
      number: minutes,
      text: i18next.t('分前'),
      fullText: i18next.t('{{count}}分前', {
        count: minutes,
      }),
      fullTextTrans: '{{count}}<0>分前<0>',
    };
  }
  const seconds = differenceInSeconds(now, date);
  if (seconds >= 1) {
    return {
      number: seconds,
      text: i18next.t('秒前'),
      fullText: i18next.t('{{count}}秒前', {
        count: seconds,
      }),
      fullTextTrans: '{{count}}<0>秒前<0>',
    };
  }
  return {
    text: i18next.t('たった今'),
    fullText: i18next.t('たった今'),
    fullTextTrans: 'たった今',
  };
};

export const generateYearListByEndYear = (startYear: number, endYear: number) => {
  const yearList = [];

  if (startYear > endYear) {
    return [];
  }

  for (let i = startYear; i <= endYear; i++) {
    yearList.push(i);
  }

  return yearList;
};

export const generateYearListByCount = (startYear: number, count: number) =>
  [...new Array(count > 0 ? count + 1 : 1)].map((x, i) => startYear - i);

export const mapYearListToOptions = (yearList: number[]) =>
  yearList.map((year, index) => ({
    order: index,
    label: i18next.t('{{year}}年', { year }),
    value: year,
  }));

export const getYearText = (start: Date | number, end: Date | number) => {
  return [_format(start!, 'yyyy年 MMM'), ' ~ ', _format(end!, 'yyyy年 MMM')].join('');
};

export const getRangeDatesText = (start: Date | number, end: Date | number) => {
  return [_format(start!, 'yyyy-MM-dd'), ' ~ ', _format(end!, 'yyyy-MM-dd')].join('');
};

export const getCurrentStartingMonth = (now: Date | number, startingMonth: number): Date => {
  return setMonth(subMonths(now, startingMonth), startingMonth);
};

export const getCurrentEndingMonth = (now: Date | number, startingMonth: number): Date => {
  return subMonths(addYears(getCurrentStartingMonth(now, startingMonth), 1), 1);
};

export const getCurrentYearText = (now: Date | number, startingMonth: number): string => {
  const currentYearStart = getCurrentStartingMonth(now, startingMonth);
  const currentYearEnd = getCurrentEndingMonth(now, startingMonth);
  return getYearText(currentYearStart, currentYearEnd);
};

export const getNextYearText = (now: Date | number, startingMonth: number): string => {
  return getCurrentYearText(addYears(now, 1), startingMonth);
};

export const isAfterDate = (date: Date | number, to: Date | number) => {
  return !isSameDay(date, to) && isAfter(date, to);
};

export const isBeforeDate = (date: Date | number, to: Date | number) => {
  return !isSameDay(date, to) && isBefore(date, to);
};

export const isSameOrAfter = (date: Date | number, to: Date | number) => {
  return isSameDay(date, to) || isAfter(date, to);
};

export const isSameOrBefore = (date: Date | number, to: Date | number) => {
  return isSameDay(date, to) || isBefore(date, to);
};

export const isBetween = (date: Date | number, from: Date | number, to: Date | number): boolean => {
  return isSameOrAfter(date, from) && isSameOrBefore(date, to);
};

export const isBetweenDates = (
  date: Date | number,
  from: Date | number,
  to: Date | number,
): boolean => {
  return date >= from && date <= to;
};

export const isToday = (date: Date | number, today?: Date | number) => {
  return isSameDay(date, today || new Date());
};

export const isFutureDate = (date: Date | number, today?: Date | number) => {
  return differenceInDays(startOfDay(date), startOfDay(today || new Date())) <= 0;
};

export const enumerateEachDay = (
  startAt: Date | number,
  endAt: Date | number,
  dateFormat?: string,
) => {
  return eachDayOfInterval({
    start: startAt,
    end: endAt,
  }).map((date: Date) => (dateFormat ? _format(date, dateFormat) : date));
};

export const getFormattedDate = (date: Date | number, dateFormat: string) => {
  return _format(date, dateFormat);
};

//
// day of week
//
export const getWeekday = (date: Date | number) => {
  return getDay(date);
};

export const getDateUnixTime = (date: Date | number) => {
  return getUnixTime(date);
};

export const getDifferenceInDays = (date: Date | number, to: Date | number) => {
  const _date = startOfDay(date);
  const _to = startOfDay(to);
  return differenceInDays(_date, _to);
};

export const dateLabelUpdateDay = (dateKey: Date | number, date: Date | number = new Date()) => {
  const day = Math.abs(getDifferenceInDays(dateKey, date));
  if (day === 0) return i18next.t('本日');
  if (day === 1) return i18next.t('昨日');
  return i18next.t(`{{day}}日前`, { day });
};

export const getAgoDays = (date: Date | number, to: Date | number) => {
  if (!date) {
    return '';
  }
  const day = Math.abs(getDifferenceInDays(date, to));
  if (day <= 0) {
    return i18next.t('本日');
  }
  if (day >= 7) {
    return i18next.t(`{{number}}週間前`, { number: Math.floor(day / 7) });
  }
  return i18next.t(`{{day}}日前`, { day });
};

export const generateMonthString = (month: string): string => {
  switch (month) {
    case '1':
      return i18next.t('1月');
    case '2':
      return i18next.t('2月');
    case '3':
      return i18next.t('3月');
    case '4':
      return i18next.t('4月');
    case '5':
      return i18next.t('5月');
    case '6':
      return i18next.t('6月');
    case '7':
      return i18next.t('7月');
    case '8':
      return i18next.t('8月');
    case '9':
      return i18next.t('9月');
    case '10':
      return i18next.t('10月');
    case '11':
      return i18next.t('11月');
    case '12':
      return i18next.t('12月');
    default:
      return 'Not Defined';
  }
};

export type RangeKey =
  | '12months'
  | '6months'
  | '3months'
  | '8weeks'
  | '30days'
  | '14days'
  | '7days'
  | 'all'
  | 'default';

export const getStartAtFromRangeKey = (
  rangeKey: RangeKey,
  endDate: undefined | Date = new Date(),
): Date => {
  if (rangeKey === '12months') {
    return subMonths(endDate, 12);
  }

  if (rangeKey === '6months') {
    return subMonths(endDate, 6);
  }

  if (rangeKey === '3months') {
    return subMonths(endDate, 3);
  }

  if (rangeKey === '8weeks') {
    return subWeeks(endDate, 8);
  }

  if (rangeKey === '30days') {
    return subDays(endDate, 30);
  }

  if (rangeKey === '7days') {
    return subDays(endDate, 7);
  }

  if (rangeKey === '14days') {
    return subDays(endDate, 14);
  }

  if (rangeKey === 'default') {
    return endDate;
  }

  return endDate;
};
export const getQuarter = (args: {
  startingMonth: number; // 1 - 12
  month: number; // 1 - 12
  year: number;
}) => {
  const { startingMonth, month, year } = args;
  const _month = month - startingMonth;
  const fiscalYear = _month < 0 ? year - 1 : year;
  const quarter = monthsToQuarters(_month < 0 ? _month + 12 : _month) + 1;
  return { fiscalYear, quarter };
};

export const getDayInCycleWeekThreshold = (date: Date): CycleWeekThresholds => {
  const d = getDay(date);
  const dateMap = [
    CycleWeekThresholds.Sunday,
    CycleWeekThresholds.Monday,
    CycleWeekThresholds.Tuesday,
    CycleWeekThresholds.Wednesday,
    CycleWeekThresholds.Thursday,
    CycleWeekThresholds.Friday,
    CycleWeekThresholds.Saturday,
  ];
  return dateMap[d];
};

export const HHmm = (date: Date | number) => {
  return _format(date, 'HH:mm');
};

export const HHmmii = (date: Date | number) => {
  return _format(date, 'HH:mm:ii');
};

export const hmmaaaa = (date: Date | number) => {
  return _format(date, 'h:mm aaaa');
};

export const redefineDateWithTime = (_date: Date | number, time: string | null) => {
  const date = time ? `${yyyyMMdd(_date)} ${time}` : yyyyMMdd(_date);
  return new Date(date);
};

export const getDatesInRange = (startDate: Date, endDate: Date) => {
  const date = new Date(startDate.getTime());
  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const getTimeInRange = (startTime: Date, endTime: Date, interval: number) => {
  const date = new Date(startTime.getTime());
  const times = [];

  while (date <= endTime) {
    times.push(new Date(date));
    date.setMinutes(date.getMinutes() + interval);
  }

  return times;
};

export const newDateOrUndefined = (
  date: Date | string | number | string | null | undefined,
): Date | undefined => {
  if (!date) return undefined;
  if (isDate(date)) return date as Date;

  const formatDate = new Date(date);
  if (isDate(formatDate)) return formatDate;
  return undefined;
};

export const generateTimeSlots = (minTime: string, maxTime: string, _inc: number) => {
  const inc = _inc || 15;
  const times = [];

  const minTimeSplt = minTime.split(':');
  const maxTimeSplt = maxTime.split(':');

  let currentHour = parseInt(minTimeSplt[0]) || 0;
  let currentMinute = parseInt(minTimeSplt[1]) || 0;

  const maxHour = parseInt(maxTimeSplt[0]) || 24;
  const maxMinute = parseInt(maxTimeSplt[1]) || 0;

  while (currentHour < maxHour || (currentHour === maxHour && currentMinute <= maxMinute)) {
    // Format the time as HH:mm
    const formattedTime = `${String(currentHour).padStart(2, '0')}:${String(currentMinute).padStart(2, '0')}`;

    // Add the time slot to the array with label and value
    times.push({
      label: formattedTime,
      value: formattedTime,
    });

    currentMinute += inc;
    if (currentMinute >= 60) {
      currentMinute = 0;
      currentHour++;
    }
  }

  return times;
};

export const timeToMinutes = (time: string): number => {
  const arr: string[] = time.split(':');
  return parseInt(arr[0]) * 60 + parseInt(arr[1]);
};

export const minutesToTime = (value: number): string => {
  const _minutes = value % 60;
  const _hours = (value - _minutes) / 60;

  const hours = _hours >= 10 ? _hours : `0${_hours}`;
  const minutes = _minutes >= 10 ? _minutes : `0${_minutes}`;
  return `${hours}:${minutes}`;
};

export const addMinutesToTime = (time: string, inc: number): string => {
  const minutes = timeToMinutes(time);
  return minutesToTime(minutes + inc);
};
