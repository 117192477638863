import { useTranslation } from 'react-i18next';
import Alert from '../../atoms/Alert';
import GearMenu, { GearMenuItem, GearMenuList } from '../../atoms/GearMenu';
import { Button } from 're-design';
import DownloadIcon from '../../quarks/icons/Download';
import { FC, memo } from 'react';
import { isIE11 } from '../../../utils/browser';

interface InvalidBrowserProps {}

const InvalidBrowser: FC<InvalidBrowserProps> = memo(() => {
  const { t } = useTranslation();

  if (isIE11()) {
    return (
      <Alert
        variant="warning"
        rightNode={
          <GearMenu
            trigger={
              <Button icon={<DownloadIcon />} onlyIconOnMobile variant="white">
                {t('ダウンロード')}
              </Button>
            }
          >
            <GearMenuList>
              <GearMenuItem
                onClick={() => {
                  window.open('https://www.microsoft.com/ja-jp/edge/download', '_blank');
                }}
              >
                {t('Microsoft Edge')}
              </GearMenuItem>
              <GearMenuItem
                onClick={() => {
                  window.open('https://www.google.com/intl/ja_jp/chrome/dr/download', '_blank');
                }}
              >
                {t('Chrome')}
              </GearMenuItem>
              <GearMenuItem
                onClick={() => {
                  window.open('https://www.apple.com/safari/', '_blank');
                }}
              >
                {t('Safari')}
              </GearMenuItem>
            </GearMenuList>
          </GearMenu>
        }
      >
        {t(
          'Internet Explorer 11には対応しておりません。Microsoft EdgeやChromeなど、他のブラウザをご利用いただきますようお願いいたします。',
        )}
      </Alert>
    );
  }
  return null;
});

export default InvalidBrowser;
